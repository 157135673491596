import Vue from 'vue';
import Vuex from 'vuex';
const axios = require('axios');
import route from 'ziggy';
import { Ziggy } from './ziggy.js';
import { getField, updateField } from 'vuex-map-fields';

let ziggyRoute = (name, params, absolute) => route(name, params, absolute, Ziggy);
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        trip_form_options: window.trip_form_options,
        cities: window.all_cities,
        trip: {
            id: null,
            name: '',
            departure_date: null,
            departure_city_id: null,
            destination_city_id: null,
            departure_city: {},
            destination_city: {},
            return_date: null,
            adults: 0,
            children: 0,
            infants: 0,
            activity_types: [],
            trip_type: null,
            hotel_budget_type: null,
            trip_budget_type: null,
            flight_type: null,
            trip_budget: 0,
            days: [],
            progress: 0,
            flight_search: {},
            hotel_search: {}
        },
        // TODO: move all activities into Vuex
        activity_budget: 0
    },

    getters: {
        getField,
    },

    mutations: {
        update_trip(state, trip) {
            for (const key in state.trip) {
                if (trip.hasOwnProperty(key)) {
                    state.trip[key] = trip[key];
                }
            }
        },

        updateField: (state, payload) => {
            // `v-model.number` doesn't work for number inputs when the field is emptied
            if (['trip.adults', 'trip.children', 'trip.infants'].indexOf(payload.path) >= 0) {
                payload.value = payload.value === '' ? 0 : payload.value;
            }

            return updateField(state, payload);
        },

        updateSelectedFlight(state, flight) {
            state.trip.flight_search = flight;
        },

        updateActivityBudget(state, amount) {
            state.activity_budget = amount;
        }
    },

    actions: {
        create_trip({commit, state}) {
            return axios.post(ziggyRoute('api.trips.store', {}, false).url(), {
                departure_date: state.trip.departure_date,
                departure_city_id: state.trip.departure_city_id,
                destination_city_id: state.trip.destination_city_id,
                return_date: state.trip.return_date
            })
            .then(function (response) {
                commit('update_trip', response.data.data);
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
        },

        update_trip({commit, state}, tripId) {
            return axios.patch(ziggyRoute('api.trips.update', tripId, false).url(), state.trip)
                .then(function (response) {
                    commit('update_trip', response.data.data);
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        update_trip_progress({commit, state}, tripId) {
            return axios.patch(ziggyRoute('api.trips.update', tripId, false).url(), {
                    progress: state.trip.progress
                })
                .then(function (response) {
                    commit('update_trip', response.data.data);
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        update_trip_field({commit, state}, {tripId, fieldName}) {
            return axios.patch(ziggyRoute('api.trips.update', tripId, false).url(), {
                    [fieldName]: state.trip[fieldName]
                })
                .then(function (response) {
                    commit('update_trip', response.data.data);
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        get_trip({commit}, tripId) {
            return axios.get(ziggyRoute('api.trips.show', tripId, false).url())
                .then(function (response) {
                    commit('update_trip', response.data.data);
                    return response;
                });
        }
    }
});
