<template>
    <div
        class="room-result wrapper col-xs-12 flex-stretch mobile-flex-stretch"
        :class="{'no-image': !hasImage}"
    >
        <!-- style='background: url({{ "/img/properties/room-example-1.jpg" | relURL }}); background-size:cover;' -->
        <div
            v-if="hasImage"
            class="image col-xs-12 col-sm-3 col-md-3"
        >
        </div>

        <div
            class="room-title col-xs-12 col-sm-9"
            :class="{'col-md-6': hasImage}"
        >
            <div class="wrapper">
                <h4>
                    <span v-if="roomCategory">{{ roomCategory }}</span><span v-if="roomCategory && bedType">, </span>
                    <span v-if="bedType">{{ bedType }}</span>
                </h4>
                <div class="room-details">
                    <p v-if="debugMode.value">{{ offer.rateCode }}</p>
                    <!-- <p>{{ offer.room.description.text }}</p> -->
                    <!-- <p>{{ offer.room.type }}</p> -->
                </div>
                <div class="room-details">
                    <ul>
                        <li>
                            <i class="fas fa-users"></i>&nbsp;
                            <!-- Sleeps {{ offer.room.guests.adults + (offer.room.guests.children.length || 0) }} -->
                        </li>
                        <li v-if="bedCount === 1">
                            1 {{ bedType }} bed
                        </li>
                        <li v-else>
                            {{ bedCount }} {{ bedType }} beds
                        </li>
                        <!-- <li><i class="fas fa-bed"></i>&nbsp; 2 Queen Beds</li> -->
                    </ul>
                </div>
            </div>
        </div>

        <div class="property-price col-xs-12 col-sm-3 col-md-3">
            <h4>${{ offer.price.total }}</h4>
            <button
                v-if="showSaveButton"
                @click="$emit('saveHotel', {hotel: hotelResult, offer})"
                class="button buttonLarge"
            >
                Save To Trip
            </button>
        </div>
    </div>
</template>

<script>
const startCase = require('lodash/startCase');
const lowerCase = require('lodash/lowerCase');

export default {
    inject: ['debugMode'],

    data() {
        return {}
    },

    props: {
        offer: {
            type: Object
        },

        showSaveButton: {
            type: Boolean,
            default: true
        },

        hotelResult: {
            type: Object
        }
    },

    computed: {
        offerNightlyRate() {
            return this.offer.price.variations.average.base
                || this.offer.price.variations.average.total;
        },

        offerTotalRate() {
            return this.offer.price.total || 0;
        },

        hasImage() {
            return false;
        },

        bedType() {
            let estimated_bed_type = this.offer.room?.typeEstimated?.bedType ?? '';
            return this.capitalize(estimated_bed_type);
        },

        bedCount() {
            return this.offer.room?.typeEstimated?.beds ?? 1;
        },

        roomCategory() {
            let estimated_category = this.offer.room?.typeEstimated?.category ?? '';
            return this.capitalize(estimated_category);
        }
    },

    methods: {
        capitalize(input) {
            return startCase(lowerCase(input));
        }
    }
}
</script>
