<template>
<main class="travel-flights">
    <div class="container">
        <section id="travel-details" class="clearfix">
                <div class="row">
                    <div class="container-wrapper col-xs-12 col-sm-4">
                        <div class="action-container col-xs-12">
                            <div class="row">
                                <div class="col-xs-12">
                                    <label for="from_location"><i class="fas fa-map-marker-alt"></i>&nbsp; Traveling From:</label>
                                    <city-selector
                                        v-model="departure_airport"
                                        type="airport"
                                        id="departure_airport"
                                    />
                                </div>

                                <div class="col-xs-12">
                                    <label for="depart_date"><i class="fas fa-calendar-alt"></i>&nbsp; Depart:</label>
                                    <flat-pickr
                                        :config="datepickerConfig"
                                        v-model="departure_date"
                                        name="departure_date"
                                    ></flat-pickr>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-wrapper col-xs-12 col-sm-4">
                        <div class="action-container col-xs-12">
                            <div class="row">
                                <div class="col-xs-12">
                                    <label for="destination_location"><i class="fas fa-map-marker-alt"></i>&nbsp; Destination:</label>
                                    <city-selector
                                        v-model="destination_airport"
                                        type="airport"
                                        id="destination_airport"
                                        :trevel-only="true"
                                    />
                                </div>

                                <div class="col-xs-12">
                                    <label for="return_date"><i class="fas fa-calendar-alt"></i>&nbsp; Return:</label>
                                    <flat-pickr
                                        :config="datepickerConfig"
                                        v-model="return_date"
                                        name="return_date"
                                    ></flat-pickr>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-wrapper col-xs-12 col-sm-4 flex-height">
                        <div class="action-container count-container col-xs-12">
                            <div class="row">
                                <div class="col-xs-12">
                                    <span class="count-title">Adults:</span>
                                    <button
                                        @click="showEditAdults = !showEditAdults"
                                        class="btn btn-link"
                                    >
                                        <template v-if="showEditAdults">
                                            <i class="far fa-times-circle"></i>
                                        </template>
                                        <template v-else>
                                            Edit <i class="fas fa-edit"></i>
                                        </template>
                                    </button>
                                    <span v-show="!showEditAdults" id="adult-count">
                                        {{ this.adults }}
                                    </span>
                                    <input v-show="showEditAdults" class="formField" type="number" v-model.number="adults" id="num-adults">
                                </div>
                            </div>
                        </div>
                        <div class="action-container count-container col-xs-12">
                            <div class="row">
                                <div class="col-xs-12">
                                    <span class="count-title">Children:</span>
                                    <button
                                        @click="showEditChildren = !showEditChildren"
                                        class="btn btn-link"
                                    >
                                        <template v-if="showEditChildren">
                                            <i class="far fa-times-circle"></i>
                                        </template>
                                        <template v-else>
                                            Edit <i class="fas fa-edit"></i>
                                        </template>
                                    </button>
                                    <span v-show="!showEditChildren" id="adult-count">
                                        {{ this.children }}
                                    </span>
                                    <input v-show="showEditChildren" class="formField" type="number" v-model.number="children" id="num-children">
                                </div>
                            </div>
                        </div>
                        <div class="action-container count-container col-xs-12">
                            <div class="row">
                                <div class="col-xs-12">
                                    <span class="count-title">Infants:</span>
                                    <button
                                        @click="showEditInfants = !showEditInfants"
                                        class="btn btn-link"
                                    >
                                        <template v-if="showEditInfants">
                                            <i class="far fa-times-circle"></i>
                                        </template>
                                        <template v-else>
                                            Edit <i class="fas fa-edit"></i>
                                        </template>
                                    </button>
                                    <span v-show="!showEditInfants" id="adult-count">
                                        {{ this.infants }}
                                    </span>
                                    <input v-show="showEditInfants" class="formField" type="number" v-model.number="infants" id="num-infants">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

        <section v-show="showFilters" id="flight-filters" class="clearfix">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="subTitle"><a href="#flight-filters">Flight Filters:</a></h2>
                </div>
            </div>

            <div class="action-container col-xs-12">
                <div class="row">
                    <div class="col-xs-12 col-md-4 dropdown">
                        <label for="cabin_class">Cabin Class:</label>
                        <select class="formField" id="cabin_class" v-model="cabin_class">
                            <option :value="null">Select...</option>
                            <option value="economy">Economy</option>
                            <option value="premium_economy">Premium Economy</option>
                            <option value="business">Business</option>
                            <option value="first_class">First</option>
                        </select>
                    </div>
                    <div class="col-xs-12 col-md-8">
                        <label for="preferred_airlines">Preferred Airline:</label>
                        <multiselect
                            id="preferred_airlines"
                            v-model="preferred_airlines"
                            tag-placeholder="Add airline"
                            placeholder="Search..."
                            track-by="id"
                            label="label"
                            :options="airlines"
                            :multiple="true"
                            :taggable="true"
                            :limit="2"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-4">
                        <label for="preferred_connections">Maximum Connections:</label>
                        <input type="radio" id="nonstop" :value="0" v-model="connections">
                        <label for="nonstop">0 (nonstop)</label>
                        <input type="radio" id="single_stop" :value="1" v-model="connections">
                        <label for="single_stop">1 Stop</label>
                        <input type="radio" id="all_flights" value="all" v-model="connections">
                        <label for="all_flights">All Flights</label>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <label>Departing Flight Time:</label>
                        <input type="checkbox" id="depart_morning" value="morning" v-model="departure_time">
                        <label for="depart_morning">Morning (5am - 12pm)</label>
                        <input type="checkbox" id="depart_afternoon" value="afternoon" v-model="departure_time">
                        <label for="depart_afternoon">Afternoon (12pm - 6pm)</label>
                        <input type="checkbox" id="depart_evening" value="evening" v-model="departure_time">
                        <label for="depart_evening">Evening (6pm - 11pm)</label>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <label>Return Flight Time:</label>
                        <input type="checkbox" id="return_morning" value="morning" v-model="return_time">
                        <label for="return_morning">Morning (5am - 12pm)</label>
                        <input type="checkbox" id="return_afternoon" value="afternoon" v-model="return_time">
                        <label for="return_afternoon">Afternoon (12pm - 6pm)</label>
                        <input type="checkbox" id="return_evening" value="evening" v-model="return_time">
                        <label for="return_evening">Evening (6pm - 11pm)</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-4 col-md-offset-8">
                        <!-- todo: trigger search -->
                        <button
                            @click="search"
                            :disabled="searchStatus === 'searching'"
                            class="button buttonLarge"
                        >
                            Search Flights &rarr;
                            <span v-if="searchStatus === 'searching'" class="ml-1 la-ball-pulse la-sm">
                                <div></div>
                                <div></div>
                                <div></div>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </section>

        <div v-if="searchStatus === 'searching'" class="search-loading fa-3x">
            <i class="fas fa-spinner fa-spin"></i>
        </div>

        <section id="flight-results" class="clearfix" v-if="results.length">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="subTitle">
                        <template v-if="this.selectedOutboundFlight">
                            <span>Selected Departure</span>
                        </template>
                        <template v-else>
                            <span>Departure options:</span>
                        </template>
                    </h2>
                </div>
            </div>

            <flight
                v-for="flight in visibleResults"
                direction="outbound"
                :flight="flight"
                :key="'outbound' + flight.id"
                :trip="trip"
                @selectFlight="selectFlight"
            />

            <button
                v-if="!this.selectedOutboundFlight && !this.loadMore"
                class="button buttonLarge loadMoreButton"
                @click="loadMore = true"
            >
                Load More
            </button>

            <template v-if="this.selectedOutboundFlight">
                <h2 class="subTitle">
                    Flight results for: Return
                </h2>
                <button class="button buttonLarge clearSelectedOutbound" @click="selectedOutboundFlight = null">
                    <i class="fas fa-long-arrow-alt-left"></i>
                    View All Departures
                </button>
                <flight
                    v-for="flight in inboundResults"
                    direction="inbound"
                    :flight="flight"
                    :key="'inbound' + flight.id"
                    @selectFlight="selectFlight"
                    :trip="trip"
                />
            </template>
        </section>

        <portal to="modal" :order="1">
            <uiv-modal
                id="save-flight-modal"
                v-model="showModal"
            >
                <template v-slot:header>
                    <button @click="showModal = false" class="button button-link pull-right close-modal">
                        <i class="fas fa-times fa-lg"></i>
                    </button>
                </template>
                <template v-slot:footer>
                    <router-link
                        class="button buttonMedium buttonBlue"
                        :to="{name: 'trip.edit.accommodations', params: {tripId: trip.id}}">
                        Continue to Accommodations &rarr;
                    </router-link>
                </template>
                <div class="text-center">
                    <h3>Flights Saved to your Trip!</h3>

                    <p>By clicking ‘Continue to Accommodations’ and skipping the Book Now option, please be aware your flights may be unavailable or priced differently at a later time. Trevel does not book your flights or guarantee any rates listed.</p>

                    <a
                        v-if="selectedItinerary"
                        target="_blank"
                        rel="nofollow"
                        :href="selectedItinerary.link"
                        class="button buttonLarge"
                    >
                        Book
                    </a>
                </div>
            </uiv-modal>
        </portal>
    </div>
</main>
</template>

<script>
import { mapState } from 'vuex';
import CitySelector from './CitySelector';
import flatPickr from 'vue-flatpickr-component';
import Flight from './Flight';
import { Modal } from 'uiv';
const axios = require('axios');
const uniqBy = require('lodash/uniqBy');
const cloneDeep = require('lodash/cloneDeep');
import Multiselect from 'vue-multiselect';

export default {
    components: {
        CitySelector,
        flatPickr,
        Flight,
        "uiv-modal": Modal,
        Multiselect
    },

    data() {
        return {
            departure_airport: null,
            destination_airport: null,

            departure_date: null,
            return_date: null,
            adults: 0,
            children: 0,
            infants: 0,

            cabin_class: null,
            preferred_airlines: [],
            connections: null,
            departure_time: ['morning', 'afternoon', 'evening'],
            return_time: ['morning', 'afternoon', 'evening'],

            datepickerConfig: {
                dateFormat: "Y-m-d",
                inline: true,
                altInput: true,
                altInputClass: 'sr-only'
            },

            // TODO: only show airlines relevant for route
            // TODO: fetch dynamically
            airlines: [
                {"id": "AA", "label": "American Airlines"},
                {"id": "DL", "label": "Delta Air Lines"},
                {"id": "B6", "label": "Jetblue Airways"},
                {"id": "WN", "label": "Southwest Airlines"},
                {"id": "UA", "label": "United Airlines"},
                {"id": "NK", "label": "Spirit Airlines"},
                {"id": "AS", "label": "Alaska Airlines"},
                {"id": "F9", "label": "Frontier Airlines"},
                {"id": "G4", "label": "Allegiant Air"},
                {"id": "SY", "label": "Sun Country Airlines"},
                {"id": "QF", "label": "Qantas"},
                {"id": "SK", "label": "SAS"}
            ],

            results: [],
            searchStatus: '',
            selectedItinerary: null,
            selectedOutboundFlight: null,

            showEditAdults: false,
            showEditChildren: false,
            showEditInfants: false,

            showModal: false,
            showFilters: false,
            loadMore: false
        }
    },

    computed: {
        visibleResults() {
            if (this.selectedOutboundFlight) {
                return [this.selectedOutboundFlight];
            }

            // Clone the results so we aren't creating side effects
            // inside of this computed method
            let results = cloneDeep(this.results);
            results = results.map(flight => {
                flight.is_recommended = false;
                return flight;
            });

            // Recommended 1 are flights that fit in the user's budget
            let recommended1 = this.withinBudget(results);
            recommended1 = this.sortByDuration(recommended1);
            recommended1 = this.tagFlights(recommended1, true, 'recommended1');

            // Recommended 2 (not tagged as recommended) are any flights
            // that don't fit in the user's budget, sorted by price
            let recommended2 = [];
            if (recommended1.length < 10) {
                recommended2 = results.filter(result => {
                    return !recommended1.map(flight => flight.id).includes(result.id);
                });
                recommended2 = this.sortByPrice(recommended2);
                recommended2 = this.tagFlights(recommended2, false, 'recommended2');
                recommended1 = recommended1.concat(recommended2);
            }

            // Filter out duplicates
            let unique = uniqBy(recommended1, result => {
                return result.outbound_legs.map(leg => {
                    return leg.airline_id + '|' + leg.flight_number;
                }).join();
            });

            return unique.splice(0, this.loadMore ? 10 : 5);
        },

        inboundResults() {
            let outbound_flight_nos = this.selectedOutboundFlight.outbound_legs.map(leg => {
                return leg.airline_id + '|' + leg.flight_number;
            }).join();

            // Clone the results so we aren't creating side effects
            // inside of this computed method
            let results = cloneDeep(this.results);
            results = results.filter((result) => {
                return outbound_flight_nos === result.outbound_legs.map(leg => {
                    return leg.airline_id + '|' + leg.flight_number;
                }).join();
            });

            let recommended1 = this.withinBudget(results);
            recommended1 = this.sortByDuration(recommended1);
            recommended1 = this.tagFlights(recommended1, true, 'recommended1');

            // Recommended 2 (not tagged as recommended) are any flights
            // that don't fit in the user's budget, sorted by price
            let recommended2 = results.filter(result => {
                return !recommended1.map(flight => flight.id).includes(result.id);
            });
            recommended2 = this.sortByPrice(recommended2);
            recommended2 = this.tagFlights(recommended2, false, 'recommended2');
            recommended1 = recommended1.concat(recommended2);

            return recommended1;
        },

        ...mapState(['trip'])
    },

    methods: {
        getDuration(flight) {
            const regex = /([\d]+)h ?([\d]*)m?/;
            let outParts = regex.exec(flight['outbound'].duration);
            let inParts = regex.exec(flight['inbound'].duration);

            // sum hours
            let total = parseInt(outParts[1], 10) * 60;
            total += parseInt(inParts[1], 10) * 60;

            // sum minutes
            if (outParts[2] !== '') {
                total += parseInt(outParts[2], 10);
            }
            if (inParts[2] !== '') {
                total += parseInt(inParts[2], 10);
            }

            return total;
        },

        search () {
            this.searchStatus = 'searching';
            this.loadMore = false;
            this.selectedOutboundFlight = null;
            this.results = [];
            axios.get(ziggyRoute('api.flights.search', {}, false).url(), {
                    params: {
                        departure_airport_id: this.departure_airport.iata_id || null,
                        departure_date: this.departure_date,
                        destination_airport_id: this.destination_airport.iata_id || null,
                        return_date: this.return_date,
                        adults: this.adults ? this.adults : 0,
                        children: this.children ? this.children : 0,
                        infants: this.infants ? this.infants : 0,
                        cabin_class: this.cabin_class,
                        preferred_airlines: this.preferred_airlines.map(airline => airline.id),
                        connections: this.connections,
                        departure_time: this.departure_time,
                        return_time: this.return_time
                    }
                })
                .then(response => {
                    this.searchStatus = 'complete';
                    this.showFilters = true;
                    this.results = response.data.itineraries;
                })
                .catch(error => {
                    this.searchStatus = 'error';
                    console.log({error});
                });
        },

        selectFlight (flight) {
            if (!this.selectedOutboundFlight) {
                this.selectedOutboundFlight = flight;
                return;
            }

            this.selectedItinerary = flight;
            this.$store.commit('updateSelectedFlight', flight);
            this.showModal = true;

            axios.post(ziggyRoute('api.flights.search', {}, false).url(), {
                trip_id: this.trip.id,
                departure_airport_id: this.departure_airport.code || this.departure_airport.iata_id,
                departure_location_type: this.departure_airport.hasOwnProperty('iata_id')
                    ? 'city' : 'airport',
                departure_date: this.departure_date,
                destination_airport_id: this.destination_airport.iata_id,
                return_date: this.return_date,
                adults: this.adults ? this.adults : 0,
                children: this.children ? this.children : 0,
                infants: this.infants ? this.infants : 0,
                cabin_class: this.cabin_class,
                preferred_airlines: this.preferred_airlines.map(airline => airline.id),
                connections: this.connections,
                departure_time: this.departure_time,
                return_time: this.return_time,
                flight_id: this.selectedItinerary.id,
                price: this.selectedItinerary.price,
                selected_itinerary: this.selectedItinerary
            })
            .then(response => {
                this.$store.dispatch('get_trip', this.trip.id);
                console.log({response});
            })
            .catch(error => {
                console.log({error});
            });
        },

        sortByDuration(list) {
            return list.sort((a, b) => {
                // Adding hours and minutes to sort by duration, descending
                let aDuration = this.getDuration(a);
                let bDuration = this.getDuration(b);
                return aDuration - bDuration;
            });
        },

        sortByPrice(list) {
            return list.sort((a, b) => {
                return a.price - b.price;
            });
        },

        withinBudget(list) {
            return list.filter(result => {
                return result.price <= (this.trip.trip_budget * .15);
            });
        },

        tagFlights(list, recommended = false, group = '') {
            return list.map(list => {
                list.is_recommended = recommended;
                if (group) {
                    list.group = group;
                }
                return list;
            });
        }
    },

    created() {
        // TODO: if there is a flight search, populate with that info.
        // Otherwise, populate from the trip details.
        this.departure_date = this.trip.departure_date;
        this.return_date = this.trip.return_date;
        this.adults = this.trip.adults;
        this.children = this.trip.children;
        this.infants = this.trip.infants;
        this.cabin_class = this.trip.flight_type;

        if (this.trip.departure_city?.id ?? false) {
            this.departure_airport = {...this.trip.departure_city};
        }

        if (this.trip.destination_city?.id ?? false) {
            this.destination_airport = {...this.trip.destination_city};
        }
    },

    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.results = [];
            vm.searchStatus = '';
            vm.selectedItinerary = null;
            vm.selectedOutboundFlight = null;
            vm.search();
        });
    },

    beforeRouteLeave (to, from, next) {
        if (to.name === 'trip.edit.accommodations' && this.trip.progress < 3) {
            this.$store.commit('update_trip', {progress: 3});
            this.$store.dispatch('update_trip_progress', this.trip.id)
                .then((response) => {
                    next();
                });
        } else {
            next();
        }
    }
}
</script>

<style lang="scss">
@import '../../../sass/colors.scss';
.count-container.col-xs-12 input[type="number"] {
        width: 75px;
}
.dayContainer {
    min-width: 0;
}
.action-container .col-xs-12 select[multiple].formField {
    height: auto;
}

.close-modal {
    color: $primary-red;

    &:hover, &:focus {
        color: $primary-red;
    }
}

.clearSelectedOutbound,
.loadMoreButton {
    margin-top: 20px;
}
</style>
