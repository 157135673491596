<template>
    <div class="row" :class="'direction-' + direction">
        <div class="overview wrapper col-xs-12 col-md-7 flex-stretch mobile-flex-stretch">
            <div class="airline col-xs-6 col-md-5">
                <h3>Airline:</h3>
                <!-- TODO: image alt -->
                <img
                    v-for="(airline, key) in flight[direction].airlines"
                    :key="key"
                    :src="`https://images.kiwi.com/airlines/64/${key}.png`"
                    class="mr-1"
                />
                <small v-if="debugMode.value">{{ flight.group }}</small>

                <!-- <ul class="amenities-glance">
                    <li><i class="fas fa-wifi"></i> <span class="sr-only">Wifi</span></li>
                </ul> -->
            </div>

            <div class="flight-time col-xs-6 col-md-7">
                <h3>Flight Time:</h3>
                <h4>
                    {{ flight[direction + '_legs'][0].depart.time }} -
                    {{ flight[direction + '_legs'][flight[direction + '_legs'].length - 1].arrive.time }}
                </h4>
                <span>
                    {{ flight[direction].duration }}
                    <template v-if="flight[direction + '_legs'].length === 2">
                        (1 stop)
                    </template>
                    <template v-else>
                        ({{ flight[direction + '_legs'].length - 1 }} stops)
                    </template>
                </span>
            </div>
        </div>

        <div class="col-xs-12 col-md-5 mobile-flex-center">
            <div class="col-xs-6">
                <h3>Round Trip:</h3>
                <h4>${{ flight.price }}</h4>
            </div>

            <div class="col-xs-6 take-action">
                <span
                    @click="$emit('showDetail')"
                    class="text-link view-details"
                >
                    <i class="fas fa-chevron-square-down"></i> View Details
                </span>
                <button
                    v-if="showSaveButton"
                    @click="$emit('selectFlight', flight)"
                    class="button buttonLarge"
                >
                    <template v-if="direction === 'outbound'">
                        View Return Flights
                    </template>
                    <template v-else>
                        Save to Trip
                    </template>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['debugMode'],

    props: {
        flight: {
            type: Object,
        },
        direction: {
            type: String,
        },
        showDetail: {
            type: Boolean
        },
        showSaveButton: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        airlines() {
            return {...this.flight.outbound.airlines, ...this.flight.inbound.airlines};
        }
    }
}
</script>

<style lang="scss">
.view-details {
    margin-bottom: 20px;
}
</style>
