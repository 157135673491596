<template>
    <div
        class="results-container col-xs-12"
        :class="{expanded: showDetails}"
    >
        <div class="row">
            <div class="event-result wrapper col-xs-12 flex-stretch">
                <div v-if="isSearchResult" class="sort-container col-xs-2">
                    <div class="col-xs-12 checkbox">
                        <input
                            :id="'add_' + activity.id"
                            type="checkbox"
                            value="checkbox"
                            name="add"
                            :checked="activity.selected"
                            @change="$emit('input', $event.target.checked)"
                        />
                        <label :for="'add_' + activity.id"><span>Bulk Add</span></label>
                    </div>
                </div>
                <div v-else-if="!summaryView" class="sort-container col-xs-2">
                    <div class="sort-direction">
                        <span class="sort-label">
                            <span class="move-up"></span>
                            Sort
                            <span class="move-down"></span>
                        </span>
                    </div>

                    <i @click="$emit('delete')" class="fas fa-times-circle deleteActivity"><span class="sr-only">Delete</span></i>
                </div>
                <div v-else class="col-xs-2 event-day">
                    <div class="wrapper">
                        <h4>
                            Day {{ dayNum }}
                            <span>{{ date }}</span>
                        </h4>
                        <h3><i class="fas fa-clock"></i>&nbsp; {{ startCase(timeSlot) }}</h3>
                    </div>
                </div>

                <div class="event-title col-xs-10 col-sm-9">
                    <!-- <h3 class="category">Adventure | <span>by <a href="#" target="_blank">Natures Finest Dolphin Experience</a></span></h3> -->
                    <h4>{{ activity.name }}</h4>
                </div>

                <div class="booking col-xs-12 col-sm-3 col-md-3">
                    <button
                        v-if="isSearchResult"
                        class="button buttonLarge"
                        @click="$emit('addActivity', activity)"
                    >
                        Add
                    </button>
                    <a
                        v-if="!isSearchResult"
                        class="button buttonLarge"
                        _target="_blank"
                        rel="noopener"
                        :href="activity.url"
                    >
                        Book Now
                    </a>
                    <span @click="showDetails = !showDetails" class="text-link">
                        View Details <i class="fas fa-chevron-square-down"></i>
                    </span>
                </div>
            </div>
        </div>

        <transition-expand>
            <div v-show="showDetails" class="row collapse">
                <div class="result-overview wrapper col-xs-12 flex-stretch">
                    <div class="activity-description col-xs-12 col-sm-6">
                        <h4>Activity Description</h4>
                        <img :src="activity.image" alt="Example Activity Image">
                        <p>{{ activity.description }}</p>
                    </div>
                    <div class="activity-description col-xs-12 col-sm-6">
                        <h4>More Details</h4>
                        <p v-if="activity.price_per_person">
                            Price: {{ formatPrice(activity.price_per_person) }} per person
                        </p>
                        <p v-else>
                            Price: <em>Free</em>
                        </p>
                        <div class="map-embed-container">
                            <div class="map-embed">
                                <address>
                                    <i class="fas fa-map-marker-alt"></i>&nbsp;
                                    {{ activity.address }}
                                </address>
                            </div>
                        </div>

                        <!-- <h3 class="price">$30 per person</h3>
                        <p class="price_details">Price for 1 traveler, including taxes and fees: $37.68
                            <b>Free Cancellation</b> within 24 hours of booking!</p> -->

                        <!-- <h4>Hours of Operation:</h4>
                        <ul>
                            <li>Monday: 7:00am - 11:00pm</li>
                            <li>Tuesday - Friday: 9:00am - 12:00pm</li>
                            <li>Saturday - Sunday: 10am - 2:00am</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </transition-expand>
    </div>
</template>

<script>
import TransitionExpand from '../TransitionExpand.vue';
import currency from 'currency.js';
const startCase = require('lodash/startCase');

export default {
    props: {
        activity: {
            type: Object
        },
        isSearchResult: {
            type: Boolean,
            default: false
        },
        value: {
            type: Boolean
        },
        summaryView: {
            type: Boolean
        },
        dayNum: {
            type: Number
        },
        timeSlot: {
            type: String
        },
        date: {
            type: String
        }
    },

    data() {
        return {
            showDetails: false
        }
    },

    components: {
        TransitionExpand
    },

    methods: {
        formatPrice(price) {
            return currency(price, {formatWithSymbol: true}).format();
        },

        startCase(val) {
            return startCase(val);
        }
    }
}
</script>

<style lang="scss">
.deleteActivity {
    cursor: pointer;
}
</style>
