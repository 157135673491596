<template>
    <div
        v-if="filteredOffers.length > 0"
        class="results-container col-xs-12"
        :class="{
            expanded: showDetail,
            tag: result.isRecommended,
            recommended: result.isRecommended,
        }"
    >
        <div class="row">
            <div class="room-result wrapper col-xs-12 flex-stretch">
                <div
                    class="image col-xs-12 col-sm-3 col-md-3"
                    :style="{
                        backgroundSize: 'cover',
                        backgroundImage: hasMedia ? `url('${result.hotel.media[0].uri}')` : 'none'
                    }"
                >
                </div>

                <div class="property-title col-xs-12 col-sm-9 col-md-6">
                    <h3>Property:</h3>
                    <h4>{{ capitalize(result.hotel.name) }}</h4>
                    <p v-if="debugMode.value">
                        {{ result.group }} / {{ result.hotel.hotelDistance.distance }}
                        {{ result.hotel.hotelDistance.distanceUnit }}
                    </p>
                    <span>
                        <em class="rating">
                            <!-- These tags need to have no whitespace between them (inline block spacing 🙄) -->
                            <i
                                v-for="n in parseInt(result.hotel.rating, 10)"
                                :key="result.hotel.hotelId + '-star-rating-' + n" class="fas fa-star"
                            ></i><i
                                v-for="n in (5 - parseInt(result.hotel.rating, 10))"
                                :key="result.hotel.hotelId + '-star-rating-' + (n + result.hotel.rating)" class="far fa-star">
                            </i>
                        </em>
                        <!-- (4/5) - 600 Reviews -->
                    </span>
                </div>

                <div class="property-price col-xs-12 col-sm-3 col-md-3">
                    <h4><em>starting at:</em> ${{ filteredOffers.length > 0 ? offerTotalRate(filteredOffers[0]) : '' }}</h4>
                    <h3>entire trip</h3>

                    <span @click="showDetail = !showDetail" class="text-link">View Details <i class="fas fa-chevron-square-down"></i></span>
                </div>
            </div>
        </div>

        <transition-expand>
            <div v-show="showDetail" class="row collapse">
                <div class="result-overview wrapper col-xs-12 flex-stretch">
                    <div class="accommodation-amenities col-xs-12 col-sm-6">
                        <ul>
                            <li
                                v-for="amenity in amenities"
                                :key="amenity.label"
                            >
                                <i :class="amenity.icon"></i>&nbsp;
                                {{ amenity.label }}
                            </li>
                        </ul>
                    </div>
                    <div class="map-embed col-xs-12 col-sm-6">
                        <address>
                            <a href="#google-maps-lightbox"><i class="fas fa-map-marker-alt"></i>&nbsp;
                                {{ capitalize(result.hotel.address.lines[0]) }}<br>
                                {{ capitalize(result.hotel.address.cityName) }}, {{ result.hotel.address.stateCode }} {{ result.hotel.address.postalCode }}
                            </a>
                        </address>
                    </div>
                </div>

                <hotel-offer
                    v-for="offer in filteredOffers"
                    :key="'offer' + offer.id"
                    :offer="offer"
                    :hotel-result="result"
                    @saveHotel="saveHotel"
                />
            </div>
        </transition-expand>
    </div>
</template>

<script>
import TransitionExpand from '../TransitionExpand.vue';
import HotelOffer from './HotelOffer.vue';
const startCase = require('lodash/startCase');
const lowerCase = require('lodash/lowerCase');
const sortBy = require('lodash/sortBy');
const isEqual = require('lodash/isEqual');
const uniqBy = require('lodash/uniqBy');

export default {
    inject: ['debugMode'],

    components: {
        HotelOffer,
        TransitionExpand
    },

    props: {
        result: {
            type: Object
        },
        selectedOffer: {
            type: Object
        },
        showSaveButton: {
            type: Boolean,
            default: true
        },
        trip: {
            type: Object
        }
    },

    data() {
        return {
            showDetail: false,
            allAmenities: {
                "WIFI": {
                    icon: 'fas fa-wifi',
                    label: 'Free Wifi'
                },
                "SWIMMING_POOL": {
                    icon: 'fas fa-swimmer',
                    label: 'Pool'
                },
                "PETS_ALLOWED": {
                    icon: 'fas fa-paw',
                    label: 'Pet Friendly'
                }
            }
        }
    },

    methods: {
        capitalize (input) {
            return startCase(lowerCase(input));
        },

        offerNightlyRate(offer) {
            return offer.price.variations.average.base
                || offer.price.variations.average.total;
        },

        offerTotalRate(offer) {
            return offer.price.total || 0;
        },

        saveHotel({hotel, offer}) {
            this.$emit('saveHotel', {hotel, offer});
        }
    },

    computed: {
        filteredOffers() {
            if (this.selectedOffer) {
                return [this.selectedOffer];
            }

            let filtered = this.result.offers.filter(offer => {
                // TODO: confirm that these are the correct rate codes to display for the public
                return ['PRO','RAC','BAR'].indexOf(offer.rateCode) >= 0;
            });

            filtered = uniqBy(filtered, offer => {
                return JSON.stringify({
                    price: offer.price.total,
                    rateCode: offer.rateCode,
                    room: offer.room
                });
            });

            return sortBy(filtered, offer => {
                return parseFloat(this.offerNightlyRate(offer));
            })
        },

        hasMedia() {
            return this.result.hotel.hasOwnProperty('media') && this.result.hotel.media.length;
        },

        nightlyRate() {
            return this.result.offers[0].price.variations.average.base
                || this.result.offers[0].price.variations.average.total;
        },

        amenities() {
            return this.result.hotel.amenities
                .filter(amenity => {
                    return this.allAmenities.hasOwnProperty(amenity);
                })
                .map(amenity => {
                    return this.allAmenities[amenity]
                });
        }
    }
}
</script>

<style lang="sass">
    .results-container .row.collapse {
        display: block;
    }
</style>
