<template>
    <transition-expand>
        <section
            v-show="section.open"
            :id="id"
            class="question-container clearfix"
        >
            <h3
                :class="{complete: section.complete}"
                @click="toggleSection"
            >
                <slot name="title"></slot>
            </h3>

            <transition-expand>
                <template v-if="!section.minified">
                    <slot name="default"></slot>
                </template>
            </transition-expand>
        </section>
    </transition-expand>
</template>

<script>
import TransitionExpand from '../TransitionExpand.vue';

export default {
    components: {
        TransitionExpand
    },

    props: ['section', 'id'],

    methods:  {
        toggleSection() {
            this.$emit('toggleSection');
        }
    }
}
</script>
