    var Ziggy = {
        namedRoutes: {"api.trips.index":{"uri":"api\/trips","methods":["GET","HEAD"],"domain":null},"api.trips.store":{"uri":"api\/trips","methods":["POST"],"domain":null},"api.trips.show":{"uri":"api\/trips\/{trip}","methods":["GET","HEAD"],"domain":null},"api.trips.update":{"uri":"api\/trips\/{trip}","methods":["PATCH"],"domain":null},"api.trips.activities.index":{"uri":"api\/trips\/{trip}\/activities","methods":["GET","HEAD"],"domain":null},"api.trips.activities.store":{"uri":"api\/trips\/{trip}\/activities","methods":["POST"],"domain":null},"api.trips.activities.update":{"uri":"api\/trips\/{trip}\/activities\/{activity}","methods":["PATCH"],"domain":null},"api.trips.activities.destroy":{"uri":"api\/trips\/{trip}\/activities\/{activity}","methods":["DELETE"],"domain":null},"api.flights.search":{"uri":"api\/flights\/search","methods":["GET","HEAD"],"domain":null},"api.flights.search.store":{"uri":"api\/flights\/search","methods":["POST"],"domain":null},"api.hotels.search":{"uri":"api\/hotels\/search","methods":["GET","HEAD"],"domain":null},"api.hotels.search.store":{"uri":"api\/hotels\/search","methods":["POST"],"domain":null},"api.cities.search":{"uri":"api\/cities","methods":["GET","HEAD"],"domain":null},"api.activities.search":{"uri":"api\/activities","methods":["GET","HEAD"],"domain":null},"trip.create":{"uri":"plan-a-trip","methods":["GET","HEAD"],"domain":null}},
        baseUrl: 'http://trevel.test/',
        baseProtocol: 'http',
        baseDomain: 'trevel.test',
        basePort: false,
        defaultParameters: []
    };

    if (typeof window.Ziggy !== 'undefined') {
        for (var name in window.Ziggy.namedRoutes) {
            Ziggy.namedRoutes[name] = window.Ziggy.namedRoutes[name];
        }
    }

    export {
        Ziggy
    }
