<template>
    <div>
        <draggable
            v-model="preferred_activities"
            handle=".sort-direction"
        >
            <div
                v-for="(activity, index) in preferred_activities"
                :key="activity.label"
                class="action-container sort col-xs-12"
            >
                <div class="row">
                    <div class="col-xs-1 order">
                        <h4>{{ index + 1 }}.</h4>
                    </div>

                    <div class="col-xs-1 sort-direction">
                        <span class="sort-label">
                            <span class="move-up"></span>
                            Sort
                            <span class="move-down"></span>
                        </span>
                    </div>

                    <div class="col-xs-1 remove pull-right">
                        <button
                            @click="preferred_activities.splice(index, 1)"
                            class="subtract-button"
                        >
                            <i class="fas fa-minus"></i>
                        </button>
                    </div>

                    <div class="col-xs-9 dropdown">
                        <label :for="'activities_' + (index + 1)" class="sr-only">
                            What are your preferred travel activities?
                            <span class="required">* <em>required</em></span>
                        </label>
                        <select
                            v-model="activity.key"
                            :id="'activities_' + (index + 1)"
                            class="formField"
                            :name="name"
                        >
                            <option value="">Select your preferred activities...</option>
                            <option
                                v-for="(activity_type, key) in trip_form_options.activity_types"
                                :value="key"
                                :key="key"
                                :disabled="isActivitySelected(key)"
                            >
                                {{ activity_type.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </draggable>

        <button
            :disabled="empty_preferred_activities.length > 0"
            class="text-andbutton"
            @click="preferred_activities.push({key: '', label: 'item' + (preferred_activities.length + 1)})"
        >
            <span class="add-button"><i class="fas fa-plus"></i></span>
            Add another activity
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import draggable from 'vuedraggable';

export default {
    props: {
        value: {
            type: Array,
        },
        name: {
            type: String,
            default: 'activity_types[]'
        }
    },

    components: {
        draggable
    },

    data() {
        return {
            preferred_activities: [
                {key: '', label: 'item1'},
                {key: '', label: 'item2'}
            ],
        }
    },

    computed: {
        empty_preferred_activities() {
            return this.preferred_activities
                .filter(activity => activity.key === '');
        },

        ...mapState(['trip_form_options'])
    },

    methods: {
        isActivitySelected(key) {
            return this.preferred_activities
                .filter(activity => activity.key === key)
                .length === 1;
        }
    },

    created() {
        if (this.value.length > 0) {
            this.preferred_activities = [...this.value];
        }
    },

    watch: {
        preferred_activities: {
            handler: function (newVal) {
                this.$emit('input', newVal);
            },
            deep: true
        }
    }
}
</script>
