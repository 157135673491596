import Vue from 'vue';
import VueRouter from 'vue-router';
import route from 'ziggy';
import { Ziggy } from './ziggy.js';
import TripPlanner from './components/TripPlanner';

// Trip Planner components
import Preferences from './components/trip-planner/Preferences';
import Dates from './components/trip-planner/Dates';
import Flights from './components/trip-planner/Flights';
import Hotels from './components/trip-planner/Hotels';
import Activities from './components/trip-planner/Activities';
import Summary from './components/trip-planner/Summary';

Vue.use(VueRouter);
window.Ziggy = Ziggy;
window.ziggyRoute = (name, params, absolute) => route(name, params, absolute, Ziggy);

let base = ziggyRoute('trip.create', {}, false).url();

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: base,
            component: TripPlanner,
            children: [
                {
                    path: '/',
                    name: 'trip.create',
                    component: Dates
                }
            ]
        },
        {
            path: base + '/:tripId',
            component: TripPlanner,
            children: [
                {
                    path: 'dates',
                    name: 'trip.edit.dates',
                    component: Dates,
                    meta: { order: 1 }
                },
                {
                    path: 'preferences',
                    name: 'trip.edit.preferences',
                    component: Preferences,
                    meta: { order: 2 }
                },
                {
                    path: 'flights',
                    name: 'trip.edit.flights',
                    component: Flights,
                    meta: { order: 3, skippable: true }
                },
                {
                    path: 'accommodations',
                    name: 'trip.edit.accommodations',
                    component: Hotels,
                    meta: { order: 4, skippable: true }
                },
                {
                    path: 'activities',
                    name: 'trip.edit.activities',
                    component: Activities,
                    meta: { order: 5 }
                },
                {
                    path: '/',
                    name: 'trip.edit',
                    component: Summary,
                    meta: { order: 6 }
                }
            ]
        },
        {
            path: '*',
        }
    ]
});
