<template>
<main>
    <div class="container">
        <div class="row">
            <div class="itinerary-title col-xs-12">
                <h4><i class="fas fa-plane-departure"></i>&nbsp; Your Selected Flights:</h4>
                <router-link
                    class="button buttonOutline"
                    :to="{name: 'trip.edit.flights', params: {tripId: trip.id}}">
                    <i class="fas fa-pencil-alt"></i> Edit
                </router-link>
            </div>
        </div>

        <flight
            v-if="trip.flight_search.selected_itinerary"
            :flight="trip.flight_search.selected_itinerary"
            :trip="trip"
            direction="outbound"
            :showSaveButton="false"
        />
        <flight
            v-if="trip.flight_search.selected_itinerary"
            :flight="trip.flight_search.selected_itinerary"
            :trip="trip"
            direction="inbound"
            :showSaveButton="false"
        />

        <div class="row">
            <div class="itinerary-title col-xs-12">
                <h4><i class="fas fa-hotel"></i>&nbsp; Your Selected Accommodations:</h4>
                <router-link
                    class="button buttonOutline"
                    :to="{name: 'trip.edit.accommodations', params: {tripId: trip.id}}">
                    <i class="fas fa-pencil-alt"></i> Edit
                </router-link>
            </div>
        </div>


        <hotel
            v-if="trip.hotel_search.selected_hotel"
            :result="trip.hotel_search.selected_hotel"
            :selectedOffer="trip.hotel_search.selected_offer"
            :showSaveButton="false"
            :trip="trip"
        >
        </hotel>

        <div class="row">
            <div class="itinerary-title col-xs-12">
                <h4><i class="fas fa-hiking"></i>&nbsp; Your Selected Activities:</h4>
                <router-link
                    class="button buttonOutline"
                    :to="{name: 'trip.edit.activities', params: {tripId: trip.id}}">
                    <i class="fas fa-pencil-alt"></i> Edit
                </router-link>
            </div>
        </div>

        <activities ref="activities" :summary-view="true"></activities>
    </div>
</main>
</template>

<script>
import { mapState } from 'vuex';
import Flight from './Flight';
import Hotel from './Hotel';
import Activities from './Activities';

export default {
    components: {
        Flight,
        Hotel,
        Activities
    },

    data() {
        return {}
    },

    computed: {
        ...mapState([
            'trip'
        ])
    },

    beforeRouteEnter (to, from, next) {
        next(vm => {
            // This is hacky. Refactor?
            if (vm.$refs['activities'].activitiesLoaded) {
                vm.$refs['activities'].init();
            }
        });
    },
}
</script>
