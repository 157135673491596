<template>
<main :class="{'summary-view': summaryView}">
    <div :class="{container: !summaryView}">
        <div v-if="!summaryView" class="subtitle">
            <div class="row">
                <div class="container-wrapper col-xs-12 col-sm-7">
                    <h2>Your Proposed Activities Schedule:</h2>
                </div>

                <div id="activities-date" class="container-wrapper col-xs-12 col-sm-5">
                    <div class="col-xs-12 dropdown">
                        <label for="trip_date"><i class="fas fa-calendar-alt"></i>&nbsp; by Date:</label>
                        <select class="formField" id="trip_date" v-model="visibleDay">
                            <option :value="null">All Days</option>
                            <option :value="day" v-for="day in trip.days" :key="'option-' + day.raw">
                                {{ day.readable }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <section
            v-for="(day, index) in visibleDays"
            :key="'title-' + day.raw"
            class="single-day clearfix"
            :class="{'travel-day': index === 0 || index === (days.length - 1)}"
        >
            <div class="day-title" v-if="!summaryView">
                <div class="row">
                    <div class="col-xs-12">
                        <h3>Day {{ index + 1 }} - {{ day.readable }}</h3>
                    </div>
                </div>
            </div>

            <div
                v-for="time_slot in ['morning', 'afternoon', 'evening']"
                :key="time_slot"
                class="timeblock"
            >
                <!-- This conditional needs to be refactored. It's basically saying:
                    Show header if there are activities, and we're not in summary view,
                    and if we're not the first or last day
                -->
                <div
                    v-if="
                        days[index][time_slot].length > 0
                        && (
                            days.length > 1
                            && index > 0
                            && index < (days.length - 1)
                        )
                        && !summaryView
                    "
                    class="row"
                >
                    <div class="col-xs-12">
                        <h4 class="timeblock-title"><i class="fas fa-clock"></i>&nbsp; {{ startCase(time_slot) }} Activities</h4>
                    </div>
                </div>

                <div
                    v-if="
                        time_slot === 'morning'
                        && days.length > 1
                        && (
                            index === 0
                            || index === (days.length - 1)
                        )
                        && !summaryView
                    "
                    class="empty results-container col-xs-12"
                >
                    <div class="row">
                        <div class="wrapper col-xs-12 flex-center text-center">
                            <div class="col-xs-12">
                                <h4>Travel Day</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <draggable
                    v-model="days[index][time_slot]"
                    group="activities"
                    filter=".empty"
                    handle=".sort-direction"
                    :disabled="summaryView"
                    :scroll="true"
                    :scroll-sensitivity="75"
                    :scroll-speed="13"
                    :force-fallback="true"
                    v-if="days.length > 1 && index > 0 && index < (days.length - 1)"
                    @change="itemDragged"
                >
                    <activity
                        v-for="(activity, activityIndex) in days[index][time_slot]"
                        :key="activity.id"
                        :activity="activity"
                        :summary-view="summaryView"
                        :dayNum="index + 1"
                        :timeSlot="time_slot"
                        :date="day.readable"
                        @delete="deleteActivity(activity, days[index][time_slot], activityIndex)"
                    />
                    <div
                        v-if="days[index][time_slot].length === 0 && !summaryView"
                        class="empty results-container col-xs-12"
                    >
                        <div class="row">
                            <div class="wrapper col-xs-12 flex-center text-center">
                                <div class="col-xs-12">
                                    <h4>No Scheduled Activities</h4>
                                    <p><a href="#search" class="smooth">Search for New Activities</a> or Move an Existing
                                        Activity Here</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
        </section>

        <section id="search" class="clearfix" v-if="!summaryView">
            <div class="subtitle">
                <div class="row">
                    <div class="container-wrapper col-xs-12 col-sm-7">
                        <h2><i class="fas fa-search"></i>&nbsp; Search Additional Activities:</h2>
                    </div>

                    <div id="search-input" class="container-wrapper col-xs-12 col-sm-5">
                        <div class="col-xs-12">
                            <label for="search_activities" class="sr-only">Search:</label>
                            <input
                                type="text"
                                id="search_activities"
                                name="search_activities"
                                placeholder="Search..."
                                class="formField"
                                v-model="activitySearchQuery"
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="container-wrapper col-xs-12 col-sm-4">
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12 dropdown">
                                <label for="activity_category"><i class="far fa-mountains"></i>&nbsp; Category:</label>
                                <select
                                    v-model="activitySearchCategory"
                                    id="activity_category"
                                    class="formField"
                                    name="activity_category"
                                >
                                    <option :value="null">Select activity type...</option>
                                    <option
                                        v-for="(activity_type, key) in trip_form_options.activity_types"
                                        :value="key"
                                        :key="key"
                                    >
                                        {{ activity_type.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-wrapper col-xs-12 col-sm-4">
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12 dropdown">
                                <label for="activity_distance"><i class="fad fa-route"></i>&nbsp; Max Distance:</label>
                                <select v-model="activitySearchDistance" id="activity_distance" class="formField">
                                    <option :value="null">Select...</option>
                                    <option :value="5">5 Miles</option>
                                    <option :value="10">10 Miles</option>
                                    <option :value="20">20 Miles</option>
                                    <option :value="50">50 Miles</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-wrapper col-xs-12 col-sm-4">
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12 dropdown">
                                <label for="activity_price"><i class="fas fa-calendar-alt"></i>&nbsp; Price:</label>
                                <select v-model="activitySearchSort" id="activity_price" class="formField">
                                    <option :value="null">Select...</option>
                                    <option :value="{term: 'price_per_person', direction: 'asc'}">Low to High</option>
                                    <option :value="{term: 'price_per_person', direction: 'desc'}">High to Low</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-wrapper col-xs-12 col-sm-4 col-sm-offset-8 text-right">
                    <button @click="search" class="button buttonLarge">Search</button>
                </div>
            </div>

            <div id="search-results" class="row">
                <div class="col-xs-12 flex-center">
                    <h2 class="subTitle"><a href="#search-results" class="smooth">Search Results:</a></h2>
                    <button
                        class="button buttonLarge"
                        :class="{inactive: selectedResults.length === 0}"
                        @click="addSelectedToSchedule"
                    >
                        Add All Selected to Schedule
                    </button>
                </div>
            </div>

            <activity
                v-for="activity in activitySearchResults"
                :key="activity.id"
                :activity="activity"
                :is-search-result="true"
                v-model="activity.selected"
                @addActivity="addActivityToSchedule"
            />
        </section>
    </div>
</main>
</template>

<script>
import { mapState } from 'vuex';
const axios = require('axios');
const flatten = require('lodash/flatten');
const isEqual = require('lodash/isEqual');
const startCase = require('lodash/startCase');
import draggable from 'vuedraggable';
import Activity from './Activity.vue';

export default {
    props: {
        summaryView: {
            type: Boolean,
            default: false
        }
    },

    components: {
        draggable,
        Activity
    },

    data() {
        return {
            days: [],
            activitiesLoaded: false,
            visibleDay: null,
            activitySearchQuery: '',
            activitySearchCategory: null,
            activitySearchDistance: null,
            activitySearchSort: null,
            activitySearchResults: [],
        }
    },

    computed: {
        visibleDays() {
            return this.visibleDay === null
                ? this.trip.days
                : [this.visibleDay];
        },

        selectedResults() {
            return this.activitySearchResults
                .filter(activity => activity.selected);
        },

        ...mapState(['trip']),
        ...mapState(['trip_form_options'])
    },

    methods: {
        search() {
            this.activitySearchResults = [];
            axios.get(ziggyRoute('api.activities.search', {}, false).url(), {
                    params: {
                        trip_id: this.trip.id,
                        search: this.activitySearchQuery,
                        category: this.activitySearchCategory,
                        max_distance: this.activitySearchDistance,
                        sort_term: this.activitySearchSort?.term || null,
                        sort_direction: this.activitySearchSort?.direction || 'asc',
                    }
                })
                .then(response => {
                    this.activitySearchResults = response.data.data.map(activity => {
                        activity.selected = false;
                        return activity;
                    });
                })
                .catch(error => {
                    console.log({error});
                });
        },

        addActivityToSchedule(activity) {
            this.activitySearchResults.forEach(result => {
                if (result.selected) {
                    result.tempSelected = true;
                    result.selected = false;
                }
            });
            activity.selected = true;
            this.addSelectedToSchedule();
            this.activitySearchResults.forEach(result => {
                if (result.tempSelected) {
                    result.selected = true;
                    delete(result.tempSelected);
                }
            });
        },

        addSelectedToSchedule() {
            // First, deal with activities that have a matching `day_tag`
            this.days.forEach((day, dayIndex, days) => {
                let dayNum = dayIndex + 1;
                if (dayNum === 1 || dayNum === days.length) {
                    return;
                }

                // `activity`.`day_tag` and `dayNum` are offset by 1 because of travel days
                let dayActivities = this.selectedResults.filter(a => a.day_tag === dayNum - 1);

                // First we fill up each time slot until it has 2 items
                ['morning', 'afternoon', 'evening'].forEach(time_slot => {
                    while (day[time_slot].length <= 1 && dayActivities.length > 0) {
                        let activity = dayActivities[0];
                        this.addActivity(activity, day, dayNum, time_slot);
                        // Remove activity from the current supply
                        dayActivities.shift();
                        // Remove selected activity from search results
                        this.activitySearchResults.splice(
                            this.activitySearchResults.findIndex(result => result.id === activity.id),
                            1
                        );
                    }
                });

                // If we still have activities for this day, continue adding them.
                while (dayActivities.length > 0) {
                    ['morning', 'afternoon', 'evening'].forEach(time_slot => {
                        let activity = dayActivities?.[0] || null;
                        if (activity) {
                            this.addActivity(activity, day, dayNum, time_slot);
                            // Remove activity from the current day's supply
                            dayActivities.shift();
                            // Remove selected activity from search results
                            this.activitySearchResults.splice(
                                this.activitySearchResults.findIndex(result => result.id === activity.id),
                                1
                            );

                        }
                    });
                }
            });

            // We're going to reuse essentially the same logic as above, but ignoring day tag
            this.days.forEach((day, dayIndex, days) => {
                let dayNum = dayIndex + 1;
                if (dayNum === 1 || dayNum === days.length) {
                    return;
                }

                // First we fill up each time slot until it has 2 items
                ['morning', 'afternoon', 'evening'].forEach(time_slot => {
                    while (day[time_slot].length <= 1 && this.selectedResults.length > 0) {
                        let activity = this.selectedResults[0];
                        this.addActivity(activity, day, dayNum, time_slot);

                        // Remove selected activity from search results
                        this.activitySearchResults.splice(
                            this.activitySearchResults.findIndex(result => result.id === activity.id),
                            1
                        );
                    }
                });
            });

            // If we still have activities, continue adding them.
            this.days.forEach((day, dayIndex, days) => {
                while (this.selectedResults.length > 0) {
                    ['morning', 'afternoon', 'evening'].forEach(time_slot => {
                        let activity = this.selectedResults?.[0] || null;
                        if (activity) {
                            this.addActivity(activity, day, dayNum, time_slot);
                            // Remove selected activity from search results
                            this.activitySearchResults.splice(
                                this.activitySearchResults.findIndex(result => result.id === activity.id),
                                1
                            );
                        }
                    });
                }
            });
        },

        itemDragged() {
            // Any time an activity is dragged, we want to go through and
            // refresh the ordering details of all the activities that may
            // have been affected
            this.days.forEach((day, dayIndex) => {
                ['morning', 'afternoon', 'evening'].forEach(time_slot => {
                    day[time_slot].forEach((activity, activityIndex) => {
                        let updated = {... activity, ...{
                            trip_day: (dayIndex + 1),
                            time_slot: time_slot,
                            slot_order: activityIndex + 1,
                        }};
                        if (!isEqual(activity, updated)) {
                            this.$set(
                                this.days[dayIndex][time_slot], activityIndex,
                                {...activity, ...updated}
                            );
                            this.updateActivity(this.days[dayIndex][time_slot][activityIndex]);
                        }
                    });
                });
            });
        },

        addActivity(activity, day, dayNum, time_slot) {
            // Because this is a newly created TripActivity, we don't have an ID for it yet.
            // So until the page refreshes the `id` and `activity_id` will be the same.
            // TODO: probably should fix this. But we're not currently using the `id`
            activity.activity_id = activity.id;
            activity.selected = false;
            activity.time_slot = time_slot;
            activity.trip_day = dayNum;
            activity.visible = true;
            activity.trevel_recommended = false;
            activity.slot_order = day[time_slot].length + 1;

            day[time_slot].push(activity);

            let route = ziggyRoute(
                'api.trips.activities.store',
                {
                    trip: this.trip.id,
                },
                false
            ).url();

            axios.post(route, {
                activity_id: activity.id,
                time_slot: activity.time_slot,
                trip_day: activity.trip_day,
                slot_order: activity.slot_order,
            })
                .catch(error => {
                    console.log({error});
                });
        },

        updateActivity(activity) {
            let route = ziggyRoute(
                'api.trips.activities.update',
                {
                    trip: this.trip.id,
                    activity: activity.activity_id
                },
                false
            ).url();

            axios.patch(route, {
                time_slot: activity.time_slot,
                trip_day: activity.trip_day,
                slot_order: activity.slot_order,
            })
                .catch(error => {
                    console.log({error});
                });
        },

        deleteActivity(activity, time_slot, index) {
            let route = ziggyRoute(
                'api.trips.activities.destroy',
                {
                    trip: this.trip.id,
                    activity: activity.activity_id
                },
                false
            ).url();

            axios.delete(route)
                .then(response => {
                    time_slot.splice(index, 1);
                })
                .catch(error => {
                    console.log({error});
                });
        },

        startCase(val) {
            return startCase(val);
        },

        init() {
            if (this.trip.id) {
                this.days = [];
                this.trip.days.forEach(day => {
                    this.days.push({
                        morning: [],
                        afternoon: [],
                        evening: []
                    });
                });
                axios.get(ziggyRoute('api.trips.activities.index', this.trip.id, false).url())
                    .then(response => {
                        response.data.data.sort((a, b) => {
                            return a.slot_order < b.slot_order
                                ? -1
                                : (a.slot_order === b.slot_order ? 0 : 1);
                        })
                        .forEach(activity => {
                            let slot = this.days?.[activity.trip_day - 1]?.[activity.time_slot] ?? null;
                            if (slot) {
                                slot.push(activity);
                            }
                        });
                        this.activitiesLoaded = true;
                    })
                    .catch(error => {
                        console.log({error});
                    });
            }
        }
    },

    created() {
        this.init();
    },

    watch: {
        days: {
            handler: function (days) {
                let total = flatten(days.map(day => {
                    return [
                        ... (day.morning || []),
                        ... (day.afternoon || []),
                        ... (day.evening || []),
                    ]
                }))
                .map(activity => {
                    return activity.price_per_person
                })
                .reduce((previousVal, nextVal) => previousVal + nextVal, 0);

                this.$store.commit('updateActivityBudget', total);
            },
            deep: true
        }
    },

    beforeRouteLeave (to, from, next) {
        if (to.name === 'trip.edit' && this.trip.progress < 5) {
            this.$store.commit('update_trip', {progress: 5});
            this.$store.dispatch('update_trip_progress', this.trip.id)
                .then((response) => {
                    next();
                });
        } else {
            next();
        }
    }
}
</script>

<style lang="scss">
main.summary-view section.travel-day {
    border-bottom: none;
    margin: 0;

    + section {
        margin-top: 0;
    }
}
</style>
