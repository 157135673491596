<template>
<main>
    <div class="container">
        <preference
            id="who-is-traveling"
            :section="sections['who-is-traveling']"
            @toggleSection="sections['who-is-traveling'].minified = !sections['who-is-traveling'].minified"
        >
            <template v-slot:title>
                Who is traveling? <span class="required">* <em>required</em></span>
            </template>
            <template v-slot:default>
                <div class="row">
                    <div
                        v-for="traveler in travelers"
                        :key="traveler.key"
                        class="container-wrapper col-xs-12 col-md-4"
                    >
                        <div class="action-container col-xs-12">
                            <div class="row">
                                <div class="col-xs-9 col-sm-10 col-md-8">
                                    <label :for="traveler.key">{{ traveler.label }}:</label>
                                    <p>{{ traveler.description }}</p>
                                </div>

                                <div class="col-xs-3 col-sm-2 col-md-4">
                                    <input
                                        type="number"
                                        :id="traveler.key"
                                        :name="traveler.key"
                                        placeholder="0"
                                        required
                                        class="formField"
                                        v-model.number="traveler.value"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12">
                        <button
                            class="button buttonLarge"
                            :class="{inactive: total_travelers <= 0}"
                            :disabled="total_travelers <= 0"
                            @click="toggleSections({from: 'who-is-traveling', to: 'preferred-activities'})"
                        >
                            Continue &rarr;
                        </button>
                    </div>
                </div>
            </template>
        </preference>

        <preference
            id="preferred-activities"
            :section="sections['preferred-activities']"
            @toggleSection="sections['preferred-activities'].minified = !sections['preferred-activities'].minified"
        >
            <template v-slot:title>
                What are your favorite travel activities? <span class="required">* <em>required</em></span>
            </template>
            <template v-slot:default>
                <div>
                    <p>Drag to reorder, minimum 2 entries required</p>

                    <preferred-activity-selector v-model="preferred_activities" />

                    <button
                        class="button buttonLarge"
                        :class="{inactive: !has_minimum_preferred_activities || empty_preferred_activities.length > 0}"
                        :disabled="!has_minimum_preferred_activities || empty_preferred_activities.length > 0"
                        @click="toggleSections({from: 'preferred-activities', to: 'trip-purpose'})"
                    >
                        Continue &rarr;
                    </button>
                </div>
            </template>
        </preference>

        <preference
            id="trip-purpose"
            :section="sections['trip-purpose']"
            @toggleSection="sections['trip-purpose'].minified = !sections['trip-purpose'].minified"
        >
            <template v-slot:title>
                What is the trip's purpose? <span class="required">* <em>required</em></span>
            </template>
            <template v-slot:default>
                <div>
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12 dropdown">
                                <select id="trip_type" v-model="trip_type" class="formField">
                                    <option :value="null">Select your favorite way to travel...</option>
                                    <option
                                        v-for="(trip_type, key) in trip_form_options.trip_types"
                                        :value="key"
                                        :key="key"
                                        >
                                        {{ trip_type.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <button
                        class="button buttonLarge"
                        :class="{inactive: trip_type === null}"
                        :disabled="trip_type === null"
                        @click="toggleSections({from: 'trip-purpose', to: 'preferred-night'})"
                    >
                        Continue &rarr;
                    </button>
                </div>
            </template>
        </preference>

        <preference
            id="preferred-night"
            :section="sections['preferred-night']"
            @toggleSection="sections['preferred-night'].minified = !sections['preferred-night'].minified"
        >
            <template v-slot:title>
                How do you like to spend the night? <span class="optional"><em>(optional)</em></span>
            </template>
            <template v-slot:default>
                <div>
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12 dropdown">
                                <select
                                    id="hotel_budget_type"
                                    v-model="hotel_budget_type"
                                    class="formField"
                                >
                                    <option :value="null">Select...</option>
                                    <option
                                        v-for="(hotel_type, key) in trip_form_options.hotel_types"
                                        :value="key"
                                        :key="key"
                                        >
                                        {{ hotel_type.label }} ({{ hotel_type.description }})
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <button
                        class="button buttonLarge"
                        :class="{inactive: hotel_budget_type === null}"
                        :disabled="hotel_budget_type === null"
                        @click="toggleSections({from: 'preferred-night', to: 'what-travel-pace'})"
                    >
                        Continue &rarr;
                    </button>

                    <button
                        v-show="!sections['preferred-night'].complete"
                        class="button buttonLarge buttonOutline"
                        @click="toggleSections({from: 'preferred-night', to: 'what-travel-pace'}, false)"
                    >
                        Skip Step &rarr;
                    </button>
                </div>
            </template>
        </preference>

        <preference
            id="what-travel-pace"
            :section="sections['what-travel-pace']"
            @toggleSection="sections['what-travel-pace'].minified = !sections['what-travel-pace'].minified"
        >
            <template v-slot:title>
                At what pace do you like to travel? <span class="required">* <em>required</em></span>
            </template>
            <template v-slot:default>
                <div>
                    <div class="action-container col-xs-12">
                        <p>If you’re adventurous, we’ll fit in more activities. Otherwise it will be a balanced pace.</p>
                        <div class="row">
                            <div class="col-xs-12 dropdown">
                                <select
                                    id="trip_budget_type"
                                    v-model="trip_budget_type"
                                    class="formField"
                                >
                                    <option :value="null">Select your desired pace of travel...</option>
                                    <option
                                        v-for="(trip_budget_type, key) in trip_form_options.trip_budget_types"
                                        :value="key"
                                        :key="key"
                                        >
                                        {{ trip_budget_type.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <button
                        class="button buttonLarge"
                        :class="{inactive: trip_budget_type === null}"
                        :disabled="trip_budget_type === null"
                        @click="toggleSections({from: 'what-travel-pace', to: 'how-do-you-fly'})"
                    >
                        Continue &rarr;
                    </button>
                </div>
            </template>
        </preference>

        <preference
            id="how-do-you-fly"
            :section="sections['how-do-you-fly']"
            @toggleSection="sections['how-do-you-fly'].minified = !sections['how-do-you-fly'].minified"
        >
            <template v-slot:title>
                How do you like to fly <span class="required">* <em>required</em></span>
            </template>
            <template v-slot:default>
                <div>
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12 dropdown">
                                <select id="how-to-fly" class="formField" v-model="flight_type">
                                    <option :value="null">Select your desired flight travel budget...</option>
                                    <option
                                        v-for="(flight_type, key) in trip_form_options.flight_types"
                                        :value="key"
                                        :key="key"
                                        >
                                        {{ flight_type.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <button
                        class="button buttonLarge"
                        :class="{inactive: flight_type === null}"
                        :disabled="flight_type === null"
                        @click="toggleSections({from: 'how-do-you-fly', to: 'trip-budget'})"
                    >
                        Continue &rarr;
                    </button>
                </div>
            </template>
        </preference>

        <preference
            id="trip-budget"
            class="trip-budget-preference"
            :section="sections['trip-budget']"
            @toggleSection="sections['trip-budget'].minified = !sections['trip-budget'].minified"
        >
            <template v-slot:title>
                What is your total trip budget? <span class="required">* <em>required</em></span>
            </template>
            <template v-slot:default>
                <div>
                    <p class="recommendation">Based on your trip details, we recommend a budget of at least <strong>{{ budget_recommendation_formatted }}</strong>.</p>
                    <div class="action-container col-xs-12">
                        <div class="row flex-center">
                            <div class="col-xs-12 col-sm-9 col-md-10">
                                <vue-slider
                                    v-model.number="trip_budget_slider"
                                    :interval="500"
                                    :min="0"
                                    :max="budget_max"
                                    :marks="true"
                                    :hide-label="true"
                                    :adsorb="true"
                                    :drag-on-click="true"
                                    @change="updateCustomBudgetAmount"
                                ></vue-slider>
                                <input
                                    class="formField customTripBudget"
                                    v-show="show_budget_editor"
                                    v-model.number="trip_budget"
                                    type="number"
                                    placeholder="Enter a dollar amount"
                                    @input="updateSliderAmount"
                                />
                            </div>

                            <div class="col-xs-12 col-sm-3 col-md-2">
                                <h3 @click="show_budget_editor = !show_budget_editor">{{ trip_budget_formatted }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </preference>

        <button
            v-show="sections['trip-budget'].open"
            class="button buttonLarge pull-right"
            @click="continueTrip"
            :class="{inactive: !sections_complete}"
            :disabled="!sections_complete"
        >
            Continue &rarr;
        </button>
    </div>
</main>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Preference from './Preference.vue';
import PreferredActivitySelector from '../PreferredActivitySelector.vue';
import VueSlider from 'vue-slider-component';
import budgetRecommendation from '../../budgetRecommendation.js';
import currency from 'currency.js';

export default {
    components: {
        Preference,
        VueSlider,
        PreferredActivitySelector
    },

    data() {
        return {
            sections: {
                'who-is-traveling': {
                    'open': true,
                    'complete': false,
                    'minified': false
                },
                'preferred-activities': {
                    'open': false,
                    'complete': false,
                    'minified': false
                },
                'trip-purpose': {
                    'open': false,
                    'complete': false,
                    'minified': false
                },
                'preferred-night': {
                    'open': false,
                    'complete': false,
                    'minified': false
                },
                'what-travel-pace': {
                    'open': false,
                    'complete': false,
                    'minified': false
                },
                'how-do-you-fly': {
                    'open': false,
                    'complete': false,
                    'minified': false
                },
                'trip-budget': {
                    'open': false,
                    'complete': false,
                    'minified': false
                },
            },

            travelers: [
                {
                    key: 'adults',
                    value: 0,
                    label: 'Adults',
                    description: '18+'
                },
                {
                    key: 'children',
                    value: 0,
                    label: 'Children',
                    description: '2-17'
                },
                {
                    key: 'infants',
                    value: 0,
                    label: 'Infants',
                    description: 'Under 2'
                }
            ],

            preferred_activities: [
                {key: '', label: 'null1'},
                {key: '', label: 'null2'}
            ],

            trip_budget_slider: 0,
            show_budget_editor: false,
            budget_max: 10000,
            updating_slider: false,
        }
    },

    computed: {
        total_travelers() {
            return this.adults + this.children + this.infants;
        },

        has_minimum_preferred_activities() {
            return this.preferred_activities
                .filter(activity => activity.key !== '')
                .length >= 2;
        },

        empty_preferred_activities() {
            return this.preferred_activities
                .filter(activity => activity.key === '');
        },

        trip_budget_formatted() {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            })
            .format(Math.round(this.trip_budget / 500) * 500);
        },

        sections_complete() {
            return Object.entries(this.sections)
                .filter(section => {
                    return section[0] !== 'preferred-night'
                        && !section[1].complete;
                })
                .length === 0;
        },

        budget_recommendation() {
            let recommendation = budgetRecommendation(this.destination_city, this.hotel_budget_type, this.trip_days.length);
            return Math.round(recommendation / 500) * 500;
        },

        budget_recommendation_formatted() {
            return currency(this.budget_recommendation, {formatWithSymbol: true}).format();
        },

        ...mapFields({
            trip_id: 'trip.id',
            adults: 'trip.adults',
            children: 'trip.children',
            infants: 'trip.infants',
            activity_types: 'trip.activity_types',
            trip_type: 'trip.trip_type',
            hotel_budget_type: 'trip.hotel_budget_type',
            flight_type: 'trip.flight_type',
            trip_budget_type: 'trip.trip_budget_type',
            trip_budget: 'trip.trip_budget',
            trip_days: 'trip.days',
            destination_city: 'trip.destination_city.name',
        }),

        ...mapState(['trip_form_options'])
    },

    methods: {
        continueTrip() {
            this.$store.commit('update_trip', {progress: 2});
            this.$store.dispatch('update_trip', this.trip_id)
                .then((response) => {
                    this.$router.push({name: 'trip.edit.flights', params: {tripId: this.trip_id}});
                });
        },

        toggleSections(sections, markClosedAsComplete = true) {
            if (sections.to === 'trip-budget' && !this.trip_budget) {
                this.trip_budget = this.budget_recommendation;
                this.trip_budget_slider = this.trip_budget;
            }
            this.sections[sections.from].minified = true;
            if (markClosedAsComplete) {
                this.sections[sections.from].complete = true;
            }
            this.sections[sections.to].open = true;
        },

        updateCustomBudgetAmount(sliderTripBudget) {
            this.trip_budget = sliderTripBudget;
        },

        updateSliderAmount(customTripBudget) {
            if (this.trip_budget > this.budget_max) {
                this.trip_budget_slider = this.budget_max;
            } else if (this.trip_budget !== this.trip_budget_slider) {
                this.trip_budget_slider = this.trip_budget;
            }
        }
    },

    watch: {
        total_travelers(newVal) {
            this.sections['who-is-traveling'].complete = newVal > 0;
        },

        trip_budget(newVal) {
            this.sections['trip-budget'].complete = (newVal > 0) ? true : false;
        },

        preferred_activities: {
            handler: function (newVal) {
                this.$store.commit('update_trip', {
                    activity_types: newVal.map(activity => activity.key)
                });
            },
            deep: true
        }
    },

    created() {
        this.travelers[0].value = this.adults;

        // Prefill data
        if (this.adults || this.children || this.infants) {
            this.sections['who-is-traveling'].complete = true;
        }

        if (this.activity_types.length) {
            this.preferred_activities = this.activity_types.map(activity => ({key: activity}));
            this.sections['preferred-activities'].complete = true;
            this.sections['preferred-activities'].open = true;
        }
        if (this.trip_type) {
            this.sections['trip-purpose'].complete = true;
            this.sections['trip-purpose'].open = true;
        }
        if (this.hotel_budget_type) {
            this.sections['preferred-night'].complete = true;
            this.sections['preferred-night'].open = true;
        }
        if (this.trip_budget_type) {
            this.sections['what-travel-pace'].complete = true;
            this.sections['what-travel-pace'].open = true;
        }
        if (this.flight_type) {
            this.sections['how-do-you-fly'].complete = true;
            this.sections['how-do-you-fly'].open = true;
        }
        if (this.trip_budget) {
            this.sections['trip-budget'].complete = true;
            this.sections['trip-budget'].open = true;
            this.trip_budget_slider = this.trip_budget;
        }

        this.$watch('travelers.0.value', function (newVal) {
            this.adults = newVal;
        });
        this.$watch('travelers.1.value', function (newVal) {
            this.children = newVal;
        });
        this.$watch('travelers.2.value', function (newVal) {
            this.infants = newVal;
        });
    }
}
</script>

<style lang="scss">
    button.text-andbutton {
        background: none;
        border: none;
    }
    .customTripBudget {
        margin-top: 20px;
    }
    .trip-budget-preference .recommendation {
        margin: 20px 0;
        font-size: 1.5rem;
    }
</style>
