<template>
    <div
        class="results-container col-xs-12"
        :class="{expanded: showDetail, tag: flight.is_recommended, recommended: flight.is_recommended}"
    >
        <flight-header
            :direction="direction"
            :flight="flight"
            :showDetail="showDetail"
            @showDetail="showDetail = !showDetail"
            @selectFlight="selectFlight"
            :showSaveButton="showSaveButton"
        />

        <transition-expand>
            <div v-show="showDetail">
                <div class="row" :class="{collapse: direction === 'outbound'}">
                    <div class="wrapper col-xs-12 col-md-7" :class="{expanded: showDetail}">
                        <template v-for="(leg, index) in flight[direction + '_legs']">
                            <div class="row mobile-flex-stretch" :key="direction + flight.id + leg.id">
                                <div class="col-xs-6 col-md-5">
                                    <h3 v-if="flight[direction + '_legs'].length > 1">Flight #{{ index + 1 }}</h3>
                                    <h4 v-if="flight[direction + '_legs'].length > 1">{{ leg.length }}</h4>
                                    <h5>{{ leg.city_from }} to {{ leg.city_to }}</h5>
                                    <span>{{ flight[direction].airlines[leg.airline_id] }} {{ leg.flight_number }}</span><br>
                                    <span>Cabin: {{ leg.cabin }}</span>
                                </div>

                                <div class="flight-time col-xs-6 col-md-7">
                                    <h4 v-if="flight[direction + '_legs'].length > 1">{{ leg.depart.time }} - {{ leg.arrive.time }}</h4>
                                    <span>
                                        {{ leg.airport_from.name }} ({{ leg.airport_from.id }}) to
                                        {{ leg.airport_to.name }} ({{ leg.airport_to.id }})
                                    </span>
                                </div>
                            </div>
                            <div
                                class="row mobile-flex-stretch"
                                v-if="leg.layover_length || false"
                                :key="direction + flight.id + leg.id + 'layover'"
                            >
                                <div class="col-xs-6 col-md-5">
                                    <h3>Stop:</h3>
                                    <h4>{{ leg.layover_length }}</h4>
                                </div>
                                <div class="flight-time col-xs-6 col-md-7">
                                    <h4>
                                        {{ leg.arrive.time }} -
                                        {{ flight[direction + '_legs'][index + 1].depart.time }}
                                    </h4>
                                    <span>{{ leg.airport_to.name }} ({{ leg.airport_to.id }})</span>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="col-xs-12 col-md-5">
                        <template v-if="direction === 'outbound' && flight.bag_prices">
                            <p>Estimated bag fees (round trip):</p>
                            <p>1 checked bag: ${{ flight.bag_prices[1] }}
                            <template v-if="flight.bag_prices[2] || false">
                                <br>2 checked bags: ${{ flight.bag_prices[2] }}
                            </template>
                            </p>

                            <p><small>Confirm bag fees, weight and size restrictions with airline(s)</small></p>
                        </template>
                    </div>
                </div>
            </div>
        </transition-expand>
    </div>
</template>

<script>
import TransitionExpand from '../TransitionExpand.vue';
import FlightHeader from './FlightHeader.vue';

export default {
    props: {
        flight: {
            type: Object,
        },
        direction: {
            type: String,
        },
        showSaveButton: {
            type: Boolean,
            default: true
        },
        trip: {
            type: Object
        }
    },

    components: {
        TransitionExpand,
        FlightHeader
    },

    data() {
        return {
            showDetail: false
        }
    },

    methods: {
        selectFlight(flight) {
            this.showDetail = false;
            this.$emit('selectFlight', flight);
        }
    },
}
</script>

<style lang="scss">
    .direction-inbound {
        border-top: 2px solid #CCCCCC;
    }
    .wrapper .flight-time {
        min-height: 180px;
    }
</style>
