<template>
<main class="travel-dates">
    <div class="container">
        <section id="travel-dates" class="question-container clearfix">
            <h3><a href="#travel-dates" class="smooth">Where would you like to go?</a></h3>

            <div class="row">
                <div class="container-wrapper col-xs-12 col-sm-6">
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12">
                                <label for="from_location">
                                    <i class="fas fa-map-marker-alt"></i>
                                    &nbsp; Traveling From:
                                </label>
                                <city-selector
                                    v-model="departure_city"
                                    type="city"
                                    id="departure_city"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <label for="depart_date">
                                    <i class="fas fa-calendar-alt"></i>
                                    &nbsp; Depart:
                                </label>
                                <flat-pickr
                                    :config="datepicker_config"
                                    v-model="departure_date"
                                    name="departure_date"
                                    placeholder="Select a date below"
                                ></flat-pickr>
                            </div>

                        </div>

                        <div class="row">
                            <div class="calendar-container" id="calendar_depart"></div>
                        </div>
                    </div>
                </div>

                <div class="container-wrapper col-xs-12 col-sm-6">
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12">
                                <label for="destination_location"><i class="fas fa-map-marker-alt"></i>&nbsp; Destination:</label>
                                <city-selector
                                    v-model="destination_city"
                                    type="city"
                                    id="destination_city"
                                    :trevel-only="true"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <label for="return_date"><i class="fas fa-calendar-alt"></i>&nbsp; Return:</label>
                                <flat-pickr
                                    :config="datepicker_config"
                                    v-model="return_date"
                                    name="return_date"
                                    placeholder="Select a date below"
                                ></flat-pickr>
                            </div>
                        </div>

                        <div class="row">
                            <div class="calendar-container" id="calendar_return"></div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-12">
                    <button v-if="is_creating" :disabled="!can_create_trip" class="button buttonLarge" @click="createTrip">Start Treveling &rarr;</button>
                    <button v-else @click="continueTrip" class="button buttonLarge">Continue &rarr;</button>
                </div>
            </div>
        </section>
    </div>
</main>
</template>

<script>
import CitySelector from './CitySelector';
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import flatPickr from 'vue-flatpickr-component';

export default {
    components: {
        CitySelector,
        flatPickr,
    },

    data() {
        return {
            departure_city: null,
            destination_city: null,
            datepicker_config: {
                dateFormat: "Y-m-d",
                inline: true,
                allowInput: true,
                altInput: true,
                altInputClass: 'formField flatpickrFormField'
            }
        }
    },

    computed: {
        is_creating () {
            return this.trip.id === null;
        },

        can_create_trip () {
            return this.trip.departure_city_id !== null
                && this.trip.destination_city_id !== null
                && this.trip.departure_date !== null
                && this.trip.return_date !== null;
        },

        ...mapFields([
            'trip.departure_date',
            'trip.return_date',
        ]),

        ...mapState(['trip']),
    },

    methods: {
        createTrip() {
            this.$store.dispatch('create_trip')
                .then((response) => {
                    this.$router.push({name: 'trip.edit.preferences', params: {tripId: response.data.data.id}});
                });
        },

        continueTrip () {
            this.$store.dispatch('update_trip', this.trip.id)
                .then((response) => {
                    this.$router.push({name: 'trip.edit.preferences', params: {tripId: this.trip.id}});
                });
        }
    },

    watch: {
        departure_city(newCity) {
            this.$store.commit('update_trip', {departure_city_id: newCity.id});
        },

        destination_city(newCity) {
            this.$store.commit('update_trip', {destination_city_id: newCity.id});
        }
    },

    created() {
        if (this.trip.departure_city.id || false) {
            this.departure_city = {...this.trip.departure_city};
        }

        if (this.trip.destination_city.id || false) {
            this.destination_city = {...this.trip.destination_city};
        }
    }
}
</script>

<style src="flatpickr/dist/themes/airbnb.css"></style>
