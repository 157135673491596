<template>
    <div class="city-selector">
        <multiselect
            :id="id"
            :value="value"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            :options="trevelOnly ? $store.state.cities : locations"
            :loading="location_loading"
            :internal-search="trevelOnly"
            :options-limit="300"
            :max-height="600"
            :preserve-search="true"
            @search-change="getLocationsDebounced"
            @input="updateLocation"
            label="name"
        >
            <template
                slot="option"
                slot-scope="{option}"
            >
                <div class="option__desc">
                    <span class="option__title">
                        {{ locationLabel(option) }}
                    </span>
                </div>
            </template>
            <template
                slot="singleLabel"
                slot-scope="{option}"
            >
                <span class="custom__tag">
                    <span>{{ locationLabel(option) }}</span>
                </span>
            </template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';
const debounce = require('lodash/debounce');
const axios = require('axios');
const CancelToken = axios.CancelToken;

export default {
    components: {
        Multiselect
    },

    props: ['value', 'type', 'id', 'trevel-only'],

    data() {
        return {
            location: null,
            location_loading: false,
            locations: [],
            location_pending_request: CancelToken.source(),
        }
    },

    computed: {
        ...mapState(['cities'])
    },

    methods: {
        updateLocation(value, id) {
            this.$emit('input', value);
        },

        locationLabel(location) {
            let label = location.name;

            if (location.subdivision) {
                label += `, ${location.subdivision.code}`;
                if (location.country) {
                    label += ` (${location.country.code})`;
                }
            } else if (location.state_abbr) {
                label += `, ${location.state_abbr}`;
                if (location.country) {
                    label += ` (${location.country})`;
                }
            }

            return label;
        },

        getLocationsDebounced: debounce(function(query) {
            if (this.trevelOnly) {
                return;
            }

            if (this.location_loading) {
                this.location_pending_request
                    .cancel('Cancelled by subsequent request');
                this.location_pending_request = CancelToken.source();
            }
            if (query.length > 1) {
                this.getLocations(query);
            }
        }, 300, {leading: true}),

        getLocations (query) {
            this.location_loading = true;

            if (!query) {
                this.location_loading = false;
                this.locations = [];
                return;
            }

            axios.get('https://api.skypicker.com/locations', {
                    cancelToken: this.location_pending_request.token,
                    params: {
                        'term': query,
                        'locale': 'en-US',
                        'limit': '10',
                        'active_only': 'true',
                        'sort': 'rank',
                        'location_types': this.type,
                    },
                    transformRequest: [function (data, headers) {
                        delete headers.common['X-Requested-With'];
                        return data;
                    }],
                })
                .then(response => {
                    this.locations = response.data.locations;
                    this.location_loading = false;
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        console.log({error});
                        this.locations = [];
                        this.location_loading = false;
                    }
                });
        },
    },

    created() {
        if (this.value) {
            this.locations = [{
                ...this.value
            }];
        }
    }
}
</script>

<style lang="scss">

</style>
