function budgetRecommendation(city, budget_type, days) {
    if (!budget_type) {
        return 0;
    }

    const recommendations = {
        "New York": {
            "budget": 137,
            "balanced": 202,
            "luxury": 394
        },
        "Philadelphia": {
            "budget": 155,
            "balanced": 201,
            "luxury": 387
        },
        "Chicago": {
            "budget": 77,
            "balanced": 305,
            "luxury": 590
        },
        "Los Angeles": {
            "budget": 100,
            "balanced": 225,
            "luxury": 600
        },
        "San Francisco": {
            "budget": 90,
            "balanced": 200,
            "luxury": 525
        },
        "Las Vegas": {
            "budget": 100,
            "balanced": 225,
            "luxury": 750
        }
    };

    let total_budget = (recommendations[city][budget_type] / 0.3) * (days - 1);
    let total_budget_per_day = total_budget / days;
    // Remove 50% of a single day's activity budget (25% for each travel day)
    let activity_budget_adjustment = total_budget_per_day / 2;

    return total_budget - activity_budget_adjustment;
}

export default budgetRecommendation;
