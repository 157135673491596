<template>
<main>
    <div class="container">
        <section id="travel-details" class="clearfix">
            <div class="row">
                <div class="container-wrapper col-xs-12 col-sm-4">
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12">
                                <label for="destination_location"><i class="fas fa-map-marker-alt"></i>&nbsp; Destination:</label>
                                <city-selector
                                    v-model="destination_city"
                                    type="city"
                                    id="destination_city"
                                    :trevel-only="true"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-wrapper col-xs-12 col-sm-4">
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12">
                                <label for="checkin_date"><i class="fas fa-calendar-alt"></i>&nbsp; Arrival:</label>
                                <flat-pickr
                                    :config="datepickerConfig"
                                    v-model="checkin_date"
                                    name="checkin_date"
                                    ref="checkin_date"
                                >
                                </flat-pickr>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-wrapper col-xs-12 col-sm-4">
                    <div class="action-container col-xs-12">
                        <div class="row">
                            <div class="col-xs-12">
                                <label for="checkout_date"><i class="fas fa-calendar-alt"></i>&nbsp; Departure:</label>
                                <flat-pickr
                                    :config="datepickerConfig"
                                    v-model="checkout_date"
                                    name="checkout_date"
                                    ref="checkout_date"
                                >
                                </flat-pickr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="accommodation-filters" class="clearfix">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="subTitle">Accommodation Filters:</h2>
                </div>
            </div>

            <div class="action-container col-xs-12">
                <div class="row">
                    <div class="col-xs-12 col-md-4">
                        <div class="col-xs-12">
                            <h3>Select Amenities:</h3>
                        </div>

                        <div
                            class="col-xs-12 checkbox"
                            v-for="amenity in amenities"
                            :key="amenity.key"
                        >
                            <input
                                type="checkbox"
                                :value="amenity.key"
                                name="wifi"
                                :id="'amenity-' + amenity.key"
                                v-model="amenity.checked"
                                :true-value="true"
                                :false-value="false"
                            />
                            <label :for="'amenity-' + amenity.key">
                                {{ amenity.label }}
                            </label>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-8">
                        <!-- <div class="col-xs-12 dropdown">
                            <label for="location">Location:</label>
                            <select id="location">
                                <option>Outside City Center</option>
                            </select>
                        </div> -->
                        <!-- <div class="col-xs-12 col-sm-7 dropdown">
                            <label for="room_type">Room Type:</label>
                            <select id="room_type">
                                <option>Standard Occupancy</option>
                            </select>
                        </div> -->
                        <!-- <div class="col-xs-12 col-sm-5 dropdown">
                            <label for="hotel_category">Category:</label>
                            <select id="hotel_category">
                                <option>Hotel</option>
                                <option>Inn</option>
                            </select>
                        </div> -->
                        <div class="row">
                            <div class="col-xs-4 col-sm-5">
                                <label for="number_rooms"># of Rooms:</label>
                                <select class="formField" id="number_rooms" v-model="rooms">
                                    <option :value="1">1</option>
                                    <option :value="2">2</option>
                                    <option :value="4">4</option>
                                    <option :value="5">5</option>
                                    <option :value="6">6</option>
                                    <option :value="7">7</option>
                                    <option :value="8">8</option>
                                    <option :value="9">9</option>
                                </select>
                            </div>

                            <div class="col-xs-8 col-sm-7 clearfix">
                                <label for="guest_rating">Hotel Rating:</label>
                                <div class="clearfix">
                                    <vue-slider
                                        v-model="ratings"
                                        :enable-cross="false"
                                        :marks="true"
                                        :min="1"
                                        :max="5"
                                        :interval="1"
                                    >
                                    </vue-slider>
                                </div>
                            </div>
                            <!-- <div class="slide-container">
                                <input type="range" min="1" max="5" value="5" class="slider" id="guest_rating">
                            </div> -->
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-md-4 pull-right">
                                <button @click="search" :disabled="status === 'searching'" class="button buttonLarge">Search &rarr;</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="accommodation-results" class="clearfix" v-if="hotelResults.length">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="subTitle">Accommodations Results:</h2>
                </div>
            </div>

            <hotel
                v-for="result in hotelResults"
                :key="result.hotel.hotelId"
                :result="result"
                :trip="trip"
                @saveHotel="saveHotel"
            >
            </hotel>

            <button
                v-if="!this.loadMore"
                class="button buttonLarge loadMoreButton"
                @click="loadMore = true"
            >
                Load More
            </button>
        </section>
        <template v-else-if="status === 'searching'">
            <div class="search-loading fa-3x">
                <i class="fas fa-spinner fa-spin"></i>
            </div>
        </template>
        <template v-else-if="status === 'complete'">
            <p>No results</p>
        </template>
    </div>

    <portal to="modal" :order="2">
        <uiv-modal
            id="save-hotel-modal"
            v-model="showModal"
        >
            <template v-slot:header>
                <button @click="showModal = false" class="button button-link pull-right close-modal">
                    <i class="fas fa-times fa-lg"></i>
                </button>
            </template>
            <template v-slot:footer>
                <router-link
                    class="button buttonMedium buttonBlue"
                    :to="{name: 'trip.edit.activities', params: {tripId: trip.id}}">
                    Continue to Activities &rarr;
                </router-link>
            </template>
            <div class="text-center">
                <h3>Hotel Saved to your Trip!</h3>
                <p></p>
                <a
                    target="_blank"
                    rel="nofollow"
                    :href="hotelSearchLink"
                    class="button buttonLarge"
                >
                    Book
                </a>
            </div>
        </uiv-modal>
    </portal>
</main>
</template>

<script>
import Hotel from './Hotel';
import { mapState } from 'vuex';
import flatPickr from 'vue-flatpickr-component';
const axios = require('axios');
import { Modal } from 'uiv';
import VueSlider from 'vue-slider-component';
import CitySelector from './CitySelector';
const cloneDeep = require('lodash/cloneDeep');

export default {
    components: {
        Hotel,
        flatPickr,
        "uiv-modal": Modal,
        VueSlider,
        CitySelector,
    },

    data() {
        return {
            destination_city: null,

            checkin_date: null,
            checkout_date: null,
            adults: 0,
            children: 0,
            infants: 0,
            rooms: 1,

            status: '',
            datepickerConfig: {
                dateFormat: "Y-m-d",
                altInput: true,
                altInputClass: 'formField',
                onChange: (selectedDates, dateStr, instance) => {
                    if (
                        (instance.element === this.$refs.checkin_date.$el && dateStr === this.checkin_date)
                        || (instance.element === this.$refs.checkout_date.$el && dateStr === this.checkout_date)
                    ) {
                        instance.clear();
                    }
                }
            },
            results: [],
            amenities: [
                {'key': 'WIFI', 'label': 'Wifi', 'checked': false},
                {'key': 'FITNESS_CENTER', 'label': 'Fitness center', 'checked': false},
                {'key': 'RESTAURANT', 'label': 'Restaurant', 'checked': false},
                {'key': 'AIR_CONDITIONING', 'label': 'Air conditioning', 'checked': false},
                {'key': 'SWIMMING_POOL', 'label': 'Swimming pool', 'checked': false},
                {'key': 'SPA', 'label': 'Spa', 'checked': false},
                {'key': 'PETS_ALLOWED', 'label': 'Pets Allowed', 'checked': false},
                {'key': 'PARKING', 'label': 'Parking', 'checked': false},
                {'key': 'KITCHEN', 'label': 'Kitchen', 'checked': false},
            ],
            ratings: [1,5],
            selectedHotel: null,
            showModal: false,
            loadMore: false
        }
    },

    computed: {
        hotelSearchLink() {
            if (!this.selectedHotel) {
                return '';
            }

            return 'http://www.google.com/search?q=' +
                this.selectedHotel.hotel.name + ', ' +
                (this.selectedHotel.hotel.address.lines[0] || '') + ', ' +
                this.selectedHotel.hotel.address.cityName + ', ' +
                this.selectedHotel.hotel.address.stateCode + ' ' +
                this.selectedHotel.hotel.address.postalCode;

            // TODO: hotel dates, format:
            // #ahotel_dates=2020-02-29,3
        },

        hotelResults() {
            let results = cloneDeep(this.results);
            return results.splice(0, this.loadMore ? 10 : 5);
        },

        ...mapState(['trip'])
    },

    methods: {
        saveHotel ({hotel, offer}) {
            this.selectedHotel = hotel;
            this.showModal = true;
            axios.post(ziggyRoute('api.hotels.search.store', {}, false).url(), {
                trip_id: this.trip.id,
                destination_city_id: this.destination_city.id,
                checkin_date: this.checkin_date,
                checkout_date: this.checkout_date,
                adults: this.adults ? this.adults : 0,
                children: this.children ? this.children : 0,
                infants: this.infants ? this.infants : 0,
                rooms: this.rooms,
                amenities: this.amenities.filter(amenity => amenity.checked)
                    .map(amenity => amenity.key),
                ratings: this.ratings,
                selected_hotel: hotel,
                selected_offer: offer
            })
            .then(response => {
                this.$store.dispatch('get_trip', this.trip.id);
                console.log({response});
            })
            .catch(error => {
                console.log({error});
            });
        },

        search () {
            this.status = 'searching';
            this.results = [];
            axios.get(ziggyRoute('api.hotels.search', {}, false).url(), {
                    params: {
                        checkin_date: this.checkin_date,
                        destination_city_id: this.destination_city.iata_id,
                        checkout_date: this.checkout_date,
                        adults: this.adults ? this.adults : 0,
                        children: this.children ? this.children : 0,
                        infants: this.infants ? this.infants : 0,
                        rooms: this.rooms,
                        amenities: this.amenities.filter(amenity => amenity.checked)
                            .map(amenity => amenity.key),
                        ratings: this.ratings,
                        budget: this.trip.trip_budget,
                    }
                })
                .then(response => {
                    this.status = 'complete';
                    this.results = response.data;
                })
                .catch(error => {
                    this.status = 'complete';
                    console.log({error});
                });
        },

        setupDefaults() {
            // TODO: if there is a hotel search, populate with that info.
            // Otherwise, populate from the trip details.
            this.checkin_date = this.trip.departure_date;
            this.checkout_date = this.trip.return_date;
            this.adults = this.trip.adults;
            this.children = this.trip.children;
            this.infants = this.trip.infants;

            if (this.trip.destination_city.id || false) {
                this.destination_city = {...this.trip.destination_city};
            }
        }
    },

    created() {
        this.setupDefaults();
    },

    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.setupDefaults();

            if (vm.results.length === 0) {
                vm.search();
            }
        });
    },

    beforeRouteLeave (to, from, next) {
        this.showModal = false;

        if (to.name === 'trip.edit.activities' && this.trip.progress < 4) {
            this.$store.commit('update_trip', {progress: 4});
            this.$store.dispatch('update_trip_progress', this.trip.id)
                .then((response) => {
                    next();
                });
        } else {
            next();
        }
    }
}
</script>

<style lang="scss">
@import '../../../sass/colors.scss';
.close-modal {
    color: $primary-red;

    &:hover, &:focus {
        color: $primary-red;
    }
}
</style>
