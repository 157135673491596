require('./bootstrap');
window.Vue = require('vue');
import router from './router.js';
import store from './store.js';
import AOS from "aos";
import PortalVue from 'portal-vue';
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import PreferredActivitySelector from './components/PreferredActivitySelector';
import currency from 'currency.js';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (window.trevel.env === 'production') {
    Sentry.init({
        dsn: 'https://b6dc4056ce1a478a94b349d1666ccd85@sentry.io/5184167',
        integrations: [new Integrations.Vue({attachProps: true, logErrors: true})],
    });
}

Vue.use(PortalVue);

const app = new Vue({
    el: '#app',
    router,
    store,

    components: {
        PreferredActivitySelector
    },

    data() {
        return {
            currentYear: new Date().getFullYear(),
            showSaveNameSuccess: false,
            debugMode: {
                value: false
            }
        }
    },

    provide() {
        return {
            debugMode: this.debugMode
        }
    },

    computed: {
        formattedBudget() {
            let total = 0;
            // TODO: calculate total from trip
            // return currency(this.trip_budget, {formatWithSymbol: true}).format();
            if (this.trip.flight_search.price || false) {
                total += this.trip.flight_search.price
            }

            if (this.trip.hotel_search.selected_offer) {
                total += parseFloat(this.trip.hotel_search.selected_offer.price.total);
            }

            total = total + this.activity_budget;

            return currency(total, {formatWithSymbol: true}).format();
        },

        ...mapState([
            'trip',
            'activity_budget',
        ]),

        ...mapFields({
            trip_name: 'trip.name',
        }),
    },

    methods: {
        logout() {
            window.document.getElementById('logout-form').submit();
        },

        updateTripName() {
            this.$store.dispatch('update_trip_field', {
                    tripId: this.$store.state.trip.id,
                    fieldName: 'name'
                })
                .then((response) => {
                    this.showSaveNameSuccess = true;
                    setTimeout(() => {
                        this.showSaveNameSuccess = false;
                    }, 5000);
                });
        },
    },

    mounted() {
        AOS.init({
            easing: 'ease-out-cubic',
            duration: 300,
            mirror: false,
            once: true
        });
    }
});
