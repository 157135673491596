<template>
    <div class="trip-planner" :id="`trip-${currentStepId}`">
        <nav id="plan-nav" class="container-fluid">
            <div class="container">
                <div class="row">
                    <a href="#" @click.prevent id="toggle-menu">
                        <i class="fa fa-bars"></i>&nbsp; Booking Progress
                    </a>
                    <ul>
                        <li v-if="$route.name === 'trip.create'">
                            <a class="active" href="#" @click.prevent>Dates</a>
                        </li>
                        <li
                            v-for="(menuItem, index) in visibleMenuItems"
                            :key="menuItem.route"
                        >
                            <router-link
                                :to="menuItemArgs(menuItem)"
                                exact-active-class="active"
                                exact
                                :event="(trip.progress + 1) < menuItemRoute(menuItem).meta.order ? '' : 'click'"
                                :class="{
                                    completed: trip.progress > index,
                                    disabled: (trip.progress + 1) < menuItemRoute(menuItem).meta.order
                                }"
                            >
                                {{ menuItem.label }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <section id="pageTitle">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1>{{ pageTitle }}</h1>
                        <router-link
                            v-if="$route.meta.skippable"
                            class="button buttonLarge buttonOutline"
                            :to="nextPage"
                        >
                            Skip {{ pageTitle }} &rarr;
                        </router-link>
                        <router-link
                            v-if="$route.name === 'trip.edit.activities'"
                            class="button buttonLarge buttonOutline"
                            :to="nextPage"
                        >
                            View Trip Summary &rarr;
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <keep-alive>
            <router-view v-if="!notFound"></router-view>
        </keep-alive>

        <div class="not-found text-center" v-if="notFound">
            <p><strong>We couldn't locate the trip with that ID.</strong></p>
            <p>
                Do you want to <router-link :to="{name: 'trip.create'}">create a new trip</router-link>?
            </p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import store from './../store.js';
import router from './../router.js';

export default {
    data() {
        return {
            notFound: false,

            menuItems: [
                {route: 'trip.edit.dates', label: 'Dates', pageTitle: 'Dates', pageId: 'dates'},
                {route: 'trip.edit.preferences', label: 'Preferences', pageTitle: 'Preferences', pageId: 'preferences'},
                {route: 'trip.edit.flights', label: 'Flights', pageTitle: 'Flights', pageId: 'flights'},
                {route: 'trip.edit.accommodations', label: 'Accommodations', pageTitle: 'Accommodations', pageId: 'accommodations'},
                {route: 'trip.edit.activities', label: 'Activities', pageTitle: 'Activities', pageId: 'activities'},
                {route: 'trip.edit', label: 'My Trip', pageTitle: 'Trip Itinerary', pageId: 'itinerary'}
            ]
        }
    },

    computed: {
        routeParams() {
            if (this.trip.id) {
                return {tripId: this.trip.id};
            }

            return {};
        },

        currentStepId() {
            if (this.$route.name === 'trip.create') {
                return 'dates';
            }

            return this.menuItems
                .filter(item => item.route === this.$route.name)[0]
                .pageId;
        },

        pageTitle() {
            if (this.$route.name === 'trip.create') {
                return 'Plan your trip';
            }

            return this.menuItems
                .filter(item => item.route === this.$route.name)[0]
                .pageTitle;
        },

        nextPage() {
            let currentMenuItemIndex = this.menuItems
                .findIndex(item => item.route === this.$route.name);

            if (currentMenuItemIndex < this.menuItems.length) {
                return {
                    name: this.menuItems[currentMenuItemIndex + 1].route,
                    params: this.routeParams
                }
            }

            return null;
        },

        visibleMenuItems() {
            return this.menuItems.filter(menuItem => {
                if (this.$route.name === 'trip.create') {
                    return menuItem.label !== 'Dates';
                }

                return true;
            });
        },

        ...mapState(['trip'])
    },

    methods: {
        menuItemRoute(menuItem) {
            return this.$router.options.routes[1]
                .children
                .filter(route => route.name === menuItem.route)[0];
        },

        menuItemArgs(menuItem) {
            if (this.trip.id) {
                return {name: menuItem.route, params: this.routeParams};
            }

            return '#';
        }
    },

    beforeRouteEnter (to, from, next) {
        if (to.params.hasOwnProperty('tripId') && to.params.tripId !== store.state.trip.id) {
            store.dispatch('get_trip', to.params.tripId)
                .then(() => {
                    // Make sure we can't go farther than the trip progress allows
                    let expectedPage = router.options
                        .routes[1]
                        .children
                        .filter(route => {
                            return route.meta.order === store.state.trip.progress + 1
                        })[0];

                    if (to.meta.order > expectedPage.meta.order) {
                        next({
                            name: expectedPage.name,
                            params: {tripId: to.params.tripId}
                        });
                    } else {
                        next();
                    }
                })
                .catch((err) => {
                    console.log({err});
                    if (err.response.status === 404) {
                        next(vm => {
                            vm.notFound = true;
                        });
                    } else {
                        // We shouldn't get here unless there is an unknown server-side error.
                        next(false);
                    }
                });
        } else {
            next(vm => {
                vm.notFound = false;
            });
        }
    },

    beforeRouteUpdate (to, from, next) {
        if (this.trip.id || to.name === 'trip.create') {
            this.notFound = false;
        }
        next();
    }
}
</script>

<style src="flatpickr/dist/themes/airbnb.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue-slider-component/theme/default.css"></style>
<style lang="scss">
    .multiselect {
        p, li {
            font-size: 1rem;
            margin: 0;
            padding: 0;
        }
    }

    .multiselect__placeholder {
        padding-top: 0;
        padding-left: 5px;
    }

    .multiselect__input, .multiselect__single {
        line-height: inherit;
    }

    .multiselect__select {
        height: calc(4rem - 4px);
    }

    .multiselect__single,
    .multiselect__input {
        margin: 0;
        position: static;
    }

    .travel-dates,
    .travel-flights {
        .flatpickr-calendar,
        .flatpickr-rContainer,
        .flatpickr-days,
        .flatpickr-calendar .dayContainer {
            width: 100%;
            max-width: none;
        }

        .flatpickrFormField {
            margin-bottom: .75rem;
        }
    }

    .multiselect__spinner {
        position: absolute;
        right: 3px;
        top: 3px;
        height: calc(4rem - 10px);
    }

    .btn-link,
    .container-wrapper .col-xs-12 .btn-link {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        font-size: 1rem;
        height: auto;
        line-height: initial;
    }
</style>
